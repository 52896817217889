// {variable} 代表變數字串，會用程式帶入值
const text = {

    // ----- API ----- //
    apiMsg: {
        sending: '資料傳送中',
        querying: '查詢中...',
        error: {
            common: 'データ読み取りエラー',
            error1: {
                msg: '資料載入錯誤，錯誤代碼：{variable}',
                tips: '請按確認重新整理頁面'
            },
        }
    },

    template: {
        // 尚未載入資料時的空白內容提示
        noContent: {
            msg: 'ワインテストクラウドプラットフォームサービスへようこそ',
            tips: '会社またはサイトがまだ選択されていません'
        }
    },

    language: {
        tc: '中文',
        jp: '日本語',
        en: 'English'
    },

    /* ----- terms / privacy page links ----- */
    privacy: [
        {
            title: 'Privacy Policy', // プライバシーポリシー
            link: '/user/rules',
            newTab: true
        },
        {
            title: 'Terms of service', // 'サービス条項
            link: '/user/terms',
            newTab: true
        }
    ],

    /* ----- footer ----- */
    footer: {
        title: '銓勝科技股份有限公司',
        address: '新竹県竹北市光明六路東二段 358 号の7',
        email: 'service@careexpert.life',
        links: [
            {
                title: 'CareExpert',
                link: 'https://www.careexpert.life/',
                newTab: true
            },
            {
                title: 'CareExpertアルコール測定プラットフォーム',
                link: '/',
                newTab: true
            },
            {
                title: 'Acetoケトンブレスチェッカー',
                link: 'https://www.aceto.io/',
                newTab: true
            }
        ]
    },

    /* ----- 主選單 ----- */
    nav: {
        coms: '企業リスト',
        sites: '拠点一覧',
        edges: 'アプリユーザー',
        devices: 'デバイス管理',
        managers: '管理権限',
        testers: '測定者一覧',
        records: 'レコード検索',
        info: 'インフォメーションパネル',
        inspection: 'チェックリスト'
    },

    /* ----- 資料庫欄位名稱對照 (按字母順序) ----- */
    fieldName: {
        active: '状態',
        addr: '住所',
        alcohol_value: 'アルコール測定値 (Raw)', // 酒測值，未經調整的原始數值

        bind_to: '對應的帳號',
        bp_dia_value: '拡張圧',
        bp_sys_value: '収縮圧',
        bp_dia_high_limit: '拡張圧 Max',
        bp_dia_low_limit: '拡張圧 Min',
        bp_sys_high_limit: '収縮圧 Max',
        bp_sys_low_limit: '収縮圧 Min',

        bp_required: '血圧測定の義務',
        brand: 'Brand',
        buildnumber: 'ビルド',
        business_no: '管理番号',

        company_custom: '略称',
        company_id: '会社',
        company_sname: '会社',
        contract_started_at: '契約開始日',
        contract_ended_at: '契約終了日',
        created_at: '追加日',
        device_id: 'Device ID',
        devicemodel_name: '型番',
        edge_id: 'エッジID',
        edge_sequence: 'Edge sequence',
        edge_type: 'タイプ',
        email: 'メール',
        employee_number: '社員番号',
        error_value: '赤色警告',
        face_verify_result: '顔認証',
        fax: 'ファックス',
        fee_at: '取引条件',
        final_value: 'アルコール値', // 酒測值，經過調整後的數值
        gain: 'Gain',
        geojson: '位置',
        heating_count: 'Heating',

        hr_value: '心拍数',
        hr_high_limit: '心拍数 Max',
        hr_low_limit: '心拍数 Min',

        id: '管理番号',
        id_card_number: '身分証番号',
        image: '写真',
        installed_at: 'インストール',
        last_seen_at: '最後に見た',
        legal_value: '法定違反値',
        licensed_at: 'ライセンス認証',
        license_id: 'LicenseID',
        license_key: '認証コード', // 待翻譯
        limit_count: '測定回数上限',
        log: 'Log',
        memo: '備考',
        mobile: '携帯電話',
        model: 'ディバイス',
        multiuser_mode: '認証モード', // 待翻譯
        name: '氏名',
        name_alt: '名称', // name 有可能非人名，而是裝置名稱，例如 edge 頁
        offset: 'オフセット',
        operation_type: '標籤', // 待翻譯
        osbuild: 'OSビルド',
        override_require_vehicle_info: 'APP 禁止略過車號輸入',
        pwm_for_engineering: 'PWM',
        recordable_type: '使用者のタイプ',
        result: '結果',
        rfid_identifier: 'RFID',
        route_type: '標籤群組', // 待翻譯
        serial_no: 'シリアル番号',
        site_custom: '自己管理 (拠点名)',
        site_id: '拠点',
        site_sname: '拠点',
        slope_ratio: 'Slope',
        sname: '量測拠点',
        supervisors: '上司',
        tel1: '電話',
        test_count: '使用回数',
        test_status: 'status',
        tested_at: '時間',
        tester_menu: '社員番号',
        tester_note: '社員備考',
        tester_sname: '組織拠点',
        types: '管理権限',
        uniqueid: 'UniqueID',
        updated_at: '最終更新',
        user_id: 'UserID',
        vehicle_id_number: '車両番号',
        version: 'バージョン',
        warning_value: 'オレンジ色警告',
        working_hours: '作業時間', // 暫時用這個 key 值

        // 工程用
        cal_nogas_adc: 'calNoGasADC',
        test_nogas_adc: 'testNoGasADC',
        test_min_adc: 'testMinADC',
    },

    /* ----- 前端自定義的欄位名稱對照 ----- */
    fieldNameCustom: {

        // 公司相關
        company: {
            name: '会社名',
            sname: '社名の略称',
            types: '公司類型',

            _address: '住所',
            _bp_dia: '拡張圧許容値',
            _bp_sys: '収縮圧許容値',
            _contract: '契約開始',
            _distributor: '代理店 ID',
            _hr: '心拍数許容値',
        },

        // 站點相關
        site: {
            name: '拠点名',
            sname: '略称'
        },

        _adc_ratio: 'test/cal',
        _appNote: 'ステータス',
        _bloodPressure: '血圧',
        _cumulativeCount: '雲端使用次數',
        _detail: '詳細',
        _face: '顔認識',
        _managerNote: '自訂備註', // 管理權限備註
        _testerNote: '自訂備註', // 受測者備註
        _usage: '使用履歴',
        _tester: 'ユーザー',
        bindAccount: 'アカウントの連動',
        licenseKey: '授権コード',

        // 未通過時的處理
        _record_statusId: '超標處理',
        _record_statusNote: '處理備註'
    },

    /* ----- 將欄位值代碼 (例如 0, -1) 轉換為文字 ----- */
    fieldParse: {

        // 裝置狀態 (括號中是舊版使用的名稱)
        // 這邊的名稱如果變動，裝置月報表 deviceUsageReport 中的名稱也需要調整
        active: {
            '0': '待命', // ENABLED (未啟用) 隨時可用的預備狀態，但尚未使用
            '1': '使用中', // ACTIVE (啟用) 吹氣一次以上就自動轉換為此狀態
            '2': '予備品', // SPARE (備品) 隨時可用，由業務確認客戶的使用狀況
            '3': 'サンプル', // SAMPLE (樣品)
            '-1': '停止', // DISABLED (停用)
            '-2': '回収' // DELETED (回收)
        },

        // 綁定管理權限
        bindAccount: {
            bind: '完成済み',
            unbound: '連動していない'
        },

        // devicemodels/list 'type' 值
        deviceModelType: {
            '0': 'その他',
            '1': 'ケーブルワインテスター',
            '2': 'ワイヤレスワインテスター',
            '3': '血圧モニター',
            '4': '体温計',
        },
        // device model 類別的排列順序
        deviceModelOrder: [
            2, 1, 3, 4, 0, 8
        ],

        // 批次新增或變更裝置狀態
        // API: '/devices/batch' 中 'op_type' 的參數值定義
        // 對應裝置狀態的 active 值
        deviceOpType: {
            'Add': '新增',
            'Spare': '予備品',
            'Sample': 'サンプル',
            'Suspend': '停止',
            'Delete': '回収',
        },

        // edge_type Edge 類型
        edgeType: {
            '1': 'Windows',
            '2': '無線アルコールチェッカー',
            '3': '有線アルコールチェッカー',
            '4': '無線血圧計',
            '5': 'タイムレコーダー',
            'null': '不明'
        },

        // Edge 的授權模式 // 待翻譯
        multiuserMode: {
            '0': 'シングル',
            '1': 'マルチプル',
            '2': 'マルチプル'
        },

        // 酒測結果
        result: {
            '0': '不合格',
            '1': '合格',
            '-1': '呼気不足',
            '-2': '未完成',
            '-3': 'タイムエラー'
        },

        // 超標處理狀態
        recordStatus: {
            '1': '取消',
            '2': '誤判',
            '3': '重測',
            '4': '直接出車',
            '5': '代班',
            '6': '其他',
        },

        // 人臉驗證結果
        faceVerifyResult: {
            '-1': '未啟用',
            '-2': 'Face API 異常',
            '0': '通過',
            '1': '未通過',
            '2': '未偵測到人臉',
            '3': '偵測到多個人臉',
            '4': '未建立人臉',
            '5': '群組不存在',
            '6': '搜尋人員失敗',
            '7': '搜尋人臉失敗',
            '8': '無網路'
        },

        // APP 禁止略過車號輸入
        overrideRequireVehicleInfo: {
            'default': '按公司設定', // null
            'on': '開啟', // true
            'off': '關閉' // false
        }

    },

    /* ----- 公司列表頁 ----- */
    pageComs: {

        list: {
            none: '無',
            notset: '未設定',
            feeRequest: '待請款'
        },

        button: {
            addCom: '会社の追加',
            addTemplate: '会社のテンプレートを作成',
            editCom: '基本資料',
            setting: '詳細設定',
            showLicense: '授権コード',
            delete: 'アーカイブ', // archive
            reverse: '取り出す' // retrieve
        },

        types: {
            '1': '直営の顧客',
            '2': '代理店',
            '3': 'システムインテグレーター',
            '4': '代理店の顧客'
        },

        // 身份辨識方式
        tester_menu: {
            '1': '携帯電話番号',
            '2': '社員番号',
            '3': '身分証番号'
        },

        // 進階設定
        configBox: {
            // APP 使用模式設定值對照表
            appDefaultBindtesterTable: [
                {value: 1, title: '單人'},
                {value: 2, title: '多人'},
                {value: 3, title: '由 APP 設定'},
            ],
            // 欄位名稱
            fieldName: {
                // 主要服務
                'alcohol_enabled': '酒測器',
                'bp_hr_enabled': '血壓計',
                'punch_in_enabled': '打卡',

                // 自訂圖檔
                'bg_enable': '自訂酒測機背景圖',
                'notice_enable': '自訂酒測機公告圖',

                // 其他欄位按字母排序
                'app_default_bindtester': 'APP 使用模式',

                'custapipull_enable': 'PULL Service APIs',
                'custapi_secret': 'API 密碼',

                'enable_check_notification': 'APP 自動檢查並提醒<br />用戶端開啟通知功能',
                'enable_guest_mode': '強制使用訪客模式',
                'enable_qrcode_default': '預設使用非文字身分識別<br /><small>(例如 QR code 或條碼)</small>',
                'enable_show_route': '訪客模式：顯示路線',
                'enable_show_vehicle_id_number': '訪客模式：顯示車號',
                'enable_vehicle_info': 'APP 車號輸入',

                'face_recognition_enabled': '人臉辨識<br /><small>Face Recognition</small>',
                'face_verification_enabled': '人臉驗證<br /><small>Face Verification</small>',

                'require_vehicle_info': 'APP 禁止略過車號輸入',

                'schedule_enabled': '班表',
                'stopLimitEN': '酒測器禁用偵測<br /><small>禁用次數 {variable}</small>',
            },
            // 選項名稱
            options: {
                'on': '開啟',
                'off': '關閉'
            },
            // 選項名稱 alternate
            optionsAlt: {
                'on': '啟用',
                'off': '停用',
            },
            // 文字說明
            textContent: {
                // 主要服務的自訂標籤說明
                alcoholOptions: '酒測自訂標籤<small> ( 可設定 4 組，每組 4 個字以內 )</small>',
                bpHrOptions: '血壓自訂標籤<small> (可設定 4 組，每組 4 個字以內)</small>',
                punchInOptions: '打卡自訂標籤<small> ( 可設定 4 組，每組 4 個字以內 )</small>',
                // CustAPI 密碼規則
                apiSecretRule: '* 密碼必須為 8~16 個字元，數字加英文字母',
                // route type 標籤群組
                routeTypesLabel: '設定標籤群組<br /><small>( 可自訂群組名稱 )</small>',
                routeTypesPlaceholder: '例如：溫層'
            },
            // 操作訊息
            message: {
                custapiSecretInvalid: 'API 密碼格式錯誤',
                routeLabelMissing: '請設定標籤群組名稱',
                success: '設定已更新',
                success64: '',
                // 缺少圖檔
                imgBgMissing: '請上傳酒測機背景圖',
                imgNoticeMissing: '請上傳酒測機公告圖',
                // 設定更新失敗
                failUpdatecfg: '設定更新失敗',
                failUpdatecfg64: '圖檔更新失敗'
            }
        },

        // 授權碼操作
        licenseBox: {

        },

        // 新增或編輯公司資料
        slidebox: {
            success: 'データ更新完了',
            error: {
                business_no: '同じ管理番号がすでにシステムに存在する',
            }
        },

        // 建立公司範本
        batchbox: {
            title: '会社テンプレートの構築',
            hint: '以下の基本情報を入力してください。システムが「会社、サイト、管理権限、被験者」を順番に作成します',
            field: {
                bind_to: '管理会計',
                business_no: '管理番号',
                distributor: '代理店',
                name: '会社名',
                sname: '表示名',
                types: '会社タイプ'
            },
            // 預設值
            default: {
                siteName: '終着駅', // 站點名稱
                siteSName: '終着駅', // 站點簡稱
                managerNote: 'ADMIN', // 管理權限備註
                testerName: 'プリセット測定者', // 受測者姓名
                testerNumber: '0000', // 受測者工號
            },
            btn: {
                submit: '開始',
                close: '閉じる'
            },
            validation: {
                business_no: '英字と数字のみ',
                noDistributor: '代理店を選択してください',
                required: '詳細を入力してください',
            },
            success: {
                company: '設立企業：{variable}',
                site: '設立拠点：{variable}',
                manager: '設立管理権限：{variable}',
                tester: '設立測定者：{variable}',
                finish: '終わったら、次のペンの作成に進むことができます'
            },
            fail: {
                business_no: '同じ管理番号がすでにシステムに存在する',
                company: '会社設立の失敗'
            }
        }

    },

    /* ----- 站點列表頁 ----- */
    pageSites: {

        list: {
            none: '無',
        },

        view: {
            info: 'サイトデータ',
            sorting: '今日のデータ'
        },

        info: '{name}：{data}', // 例如 站點自訂：無

        fastdial: {
            sum: '{variable} 各サイト',
            placeholder: 'サイト名を入力'
        },

        control: {
            site: {
                add:'サイトの追加',
                edit: 'サイトの編集',
                delete: 'サイトの削除',
                delConfirm: 'このサイトを削除しますか？',
                inventorySetting: '庫存設定'
            },
            note: {
                add:'備考の追加',
                edit: '編集',
                delete: '削除',
                delConfirm: 'この備考を削除しますか？'
            }
        },

        // 站點備註
        noteDialog: {
            hint: '此備註專屬於您<strong>目前使用中的管理權限</strong>，您無法看到其他人或其他管理權限建立的備註內容'
        },

        msg: {
            noCompany: '需選擇公司',
            delCheckFailed: '無法刪除，此站點還有管理權限與受測者'
        }
    },

    /* ----- Edges ----- */
    pageEdges: {

        sum: '{variable} アプリユーザー',

        showSingleModeList: {
            btn: '單人模式用戶端',
            hint: '(單人模式用戶端可能無隸屬站點)'
        },

        btn: {
            queryUsage: '検索', // 查詢使用紀錄
        },

        // 受測者欄位，hover 顯示更多資料
        tester: {
            employeeNumber: '社員番号：',
            mobile: '携帯電話：'
        },

        // 查詢使用紀錄
        usageQuery: {
            title: '最近量測紀錄',
            noData: '無最近量測紀錄'
        }

    },

    /* ----- Devices ----- */
    pageDevices: {

        list: {
            searchKey: '搜尋條件：{variable}', // 顯示所輸入的搜尋條件

        },

        btn: {
            setting: '設定',
            queryUsage: '検索',
        },

        // 裝置設定
        setting: {
            title: '裝置設定',
            action: {
                // 轉移到其他站點
                transfer: {
                    title: '拠点移動'
                },
                // 變更序號
                serialNo: {
                    title: 'シリアル番号 (空白の場合は変更しない)',
                    placeholder: '新しいシリアル番号を入力'
                },
                // 變更狀態
                active: {
                    title: '状態変更'
                }
            },
            fieldTitle: {
                id: '管理番号：',
                modelName: 'モデル：',
                serialNo: 'シリアル番号：',
                site: '拠点：',
                status: '状態：',
                licenseKey: '認証コード：',
            },
            msg: {
                m01: '設定は更新されていない',
                m02: '駅を選択してください',
                m03: '本当にキャンセルしますか？',
                m04: 'アップデートに失敗しました。シリアル番号が重複していないか確認してください。',
                m05: 'セットアップ完了',
            }
        },

        // 批次新增
        storeBatch: {
            title: '批次新增：{variable}',
            btn: {
                cancel: '取消',
                close: '閉じる',
                confirm: '確認',
                trigger: 'デバイスの追加',
                tips: {
                    noSite: '需選擇站點'
                }
            },
            hint: 'デバイスモデルを選択してください。一度に複数のデータを追加したい場合は、完全なシリアル番号を<strong>半角スペース</strong>で区切ってください',
            sum: '數量：{variable}',
            msg: {
                emptyInput: '請輸入序號',
                foundExist: 'デバイスが重複しています',
                invalidInput: '無效的序號',
                invalidModel: '機種を選択してください',
                success: '成功新增 {variable} 個裝置',
                successTransfer: '成功轉移 {variable} 個裝置',
            }
        },

        // 其他批次操作 (停用 suspend / 回收 delete)
        batchAction: {
            title: '批次設定：{variable}',
            btn: {
                close: '閉じる',
                confirm: '確認',
                trigger: '批次設定',
                tips: '可跨公司站點操作'
            },
            hint: '將不同序號以空白隔開，可批次處理多筆資料',
            sum: '數量：{variable}',
            msg: {
                success: '設定完成',
                invalidInput: '無效的序號',
                failed: '批次設定失敗',
                failedSN: '錯誤的序號：{variable}',
                failedUpdate: '設定失敗：{variable}'
            }
        },

        // 搜尋
        search: {
            placeholder: 'デバイスの検索',
            remark: '検索したいシリアル番号を入力してください。単独でのあいまい検索や複数での完全一致検索をサポートしています<br />複数での検索には完全なシリアル番号を入力してください。また異なるシリアル番号はブランクでスペースを空けてください。',
            company: '会社',
            error: {
                noCompany: '尚未選擇公司'
            }
        }
    },

    /* ----- Managers ----- */
    pageManagers: {
        button: {
            add: '権限の追加',
            edit: '権限の編集',
            bindExplain: '連動の説明',
            tips: {
                noSite: '需選擇站點'
            }
        },

        panel: {
            add: '権限の追加：{variable}',
            edit: '権限の編集：{variable}',
        },

        message: {
            bindAccount: '管理権限はアカウントの連動後、使用できるようになります',
            supervisorsHint: '上司の社員番号を入力してください。1名以上を入力する場合は半角のカンマで区切ってください。'
        },

        bindExplainBox: {
            title: '管理権限とアカウントの連動について',
            btn: {
                close: '確認',
                bindExplain: '綁定說明'
            }
        },

        bindHintBox: {
            title: '管理権限の追加完了',
            titleAlt: '權限綁定說明',
            desc:
            `<p>管理權限建立後會自動關聯對應的雲端平台帳號</p>
            <p>{variable}</p>
            <ul>
                <li>如果此帳號已註冊過，將自動完成綁定</li>
                <li>若尚未註冊，請通知指定對象使用此 Email 註冊並登入雲端平台，即可完成綁定</li>
            </ul>`,
            notSet: '尚未設定對應的雲端平台帳號'
        },

        delete: {
            alert1: 'この管理権限を削除しますか？',
            alert2: '現在使用中の管理権限です。削除しますか？'
        },

        // 根據公司類型，限制可新增的管理權限
        // 注意此處的 Object key 值代表的是公司類型 (company types)
        // Array 中的值代表的是管理權限
        companyRules: {
            1: [3, 4], // 直營客戶 com, sm
            2: [2, 3, 4], // 經銷商 dm, com, sm
            3: [2, 3, 4], // 系統整合商 dm. com, sm
            4: [3, 4] // 經銷客戶 com, sm
        },

        // 管理權限數字轉換 (同 account.roles)
        roleCode: {
            '1': 'cem',
            '2': 'dm',
            '3': 'com',
            '4': 'sm',
            '5': 'tm',
            '6': 'regular',
        }
    },

    /* ----- Testers ----- */
    pageTesters: {
        // ex: 共 12 位受測者
        sum: '計 {variable} 名の測定者',

        list: {
            btn: {
                edit: '編集',
                delete: '削除',
                transfer: '移動',
            }
        },

        button: {
            add: '使用者の追加',
            edit: '使用者の編集',
            close: '閉じる',
            renewDbExplain: '手動でのデータベースの更新方法について',
            tips: {
                noSite: '需選擇站點'
            }
        },

        panel: {
            add: '使用者の追加：{variable}',
            edit: '使用者の編集：{variable}',
        },

        placeholder: {
            vehicleIdNumber: '車號範例：ABC-5678'
        },

        renewDbHintBox: {
            title: '使用者の追加完了',
            desc: '現在携帯電話のアプリまたはWindowsのデスクトップアプリケーションをご使用の場合、今回追加されたユーザーは<strong>手動でデータベースを更新</strong>または<strong>アプリ/Windowsのデスクトップアプリケーション</strong>を再起動後に有効になります'
        },

        renewDbExplainBox: {
            title: '手動で携帯電話のアプリまたはWindows のデスクトップアプリケーションのデータベースを更新する'
        },

        search: {
            placeholder: '使用者の検索',
            hint: 'キーワードで使用者を検索する場合、選択した会社のすべての使用者を検索できます<br />Enter キーを押すまたは &#10132; ボタンを押せば検索が開始されます',
            condition: {
                type: 'タイプ',
                company: '会社'
            },
            error: {
                nocompany: '需選擇公司',
                noinput: '未輸入關鍵字',
                notFound: '沒有符合的受測者',
                apiError: '發生錯誤，請稍後再試'
            },
            prefixTag: '検索結果'
        },

        batchStore: {
            trigger: '批次新增',
            box: {
                title: '批次新增受測者：{variable}',
                placeholder: '輸入格式範例：\r\n小明 123456 abc-1234\r\n小華 87654321',
                previewTitle: '已輸入的受測者資料 (單次新增上限 200 筆)',
                input: {
                    name: '氏名',
                    type: '{type}',
                    vehicle: '車両番号',
                    missing: '尚未輸入',
                    colon: '：' // 冒號
                },
                tips: {
                    t1: '受測者的{name}與{type}為必填，可選填車號',
                    t2: '請以「空白」區隔不同欄位，以「Enter鍵換行」區分不同受測者 (英文會自動轉換為大寫)',
                    t3: 'Excel 提示',
                },
                submit: '確認' // 確定送出按鈕
            },
            success: '成功新增 {variable} 名受測者',
            error: {
                custapiDisabled: {
                    title: 'PULL Service APIs 未開啟',
                    content: 'この機能を有効にするには、販売代理店にお問い合わせください'
                },
                noinput: '尚未輸入內容',
                incomplete: '輸入的資料不完整'
            }
        },

        // 人臉辨識
        face: {
            title: '写真のアップロード：{variable}',
            info: {
                company: '会社：',
                site: '拠点：',
                name: '氏名：',
                number: '社員番号：',
                desc: 'システムはアップロードされた写真を保存しません'
            },
            button: {
                invalid: '--',
                valid: '更新',
            },
            msg: {
                authAlert: '「ユーザ管理」または「拠点管理」の権限が必要',
                uploadSuccess: '画像のアップロードに成功',
                uploadFail: '画像のアップロードに失敗しました',
            }
        },

        // 轉移受測者
        transfer: {
            desc: '將受測者轉移到其他站點',
            error: {
                noDestination: '尚未選取公司或站點',
                sameSite: '目的站點與原站點相同'
            },
            success: '轉移成功'
        },

        bpRequired: {
            'true': '必要',
            'false': '--'
        },

        // 新增或編輯受測者資料
        slidebox: {
            success: 'データ更新完了',
            error: {
                employee_number: 'スタッフ番号の重複',
            }
        },

        message: {
            deleteTester: '本当に削除するのか？',
        }

    },

    /* ----- Records ----- */
    pageRecords: {

        panel: {
            date: {
                label: '日付：',
                today: '今日',
                yesterday: '昨日',
                thisweek: '今週',
                thismonth: '今月',
                lastmonth: '上月',
                range: '期間',
                rangePlaceholder: '日付範囲選択',
            },
            siteType: {
                label: '該当者：',
                test: {
                    label: '当ステーションでの測定',
                    desc: '訪問者を含む、このステーションの全職員の測定記録'
                },
                org: {
                    label: '当駅での設立',
                    desc: 'このステーション（外部ステーションである場合もある）におけるスタッフの調査記録の整理'
                },
                all: {
                    label: 'All'
                }
            },
            tester: {
                name: {
                    label: '氏名',
                    desc: 'お名前を入力してください',
                },
                employeeNumber: {
                    label: '社員番号',
                    desc: 'スタッフ番号を入力してください',
                },
                deviceId: {
                    label: 'Device',
                    desc: 'Device ID',
                },
                edgeId: {
                    label: 'Edge',
                    desc: 'Edge ID',
                },
                serialNo: {
                    label: 'SN',
                    desc: 'シリアル番号 (Fuzzy)',
                }
            },
            others: {
                failedOnly: 'ただし不合格',
                unfinished: '未完成を含む'
            },
            button: {
                createInspection: '酒気帯び確認記録',
                exportExcel: '輸出 Excel',
                operation: 'シフト統計',
                showMap: '地図',
                submit: '検索開始',
                tab_crosscompany: 'アウトソース',
                tab_measure: '測定記録',
                tab_punch: '出退勤記録',
            },
            // 此次查詢的查詢條件，有些需要顯示冒號
            queryCondition: {
                date: '日付：',
                name: '氏名：',
                employeeNumber: '社員番号：',
                resultFailed: '不合格'
            }
        },

        message: {
            emptyContent: '条件を設定したら「検索開始」',
        },

        error: {
            msg1: '測定記録が見つからない',
            msg2: 'データ形式エラー',
        },

        crossCompanyFilter: {
            // 可以看到「委外」按鈕的公司 ID 白名單
            whiteList: {
                TW: [429], // #429 奇美實業
            }
        }
    },

    /* ----- 資訊統計頁 ----- */
    pageInfo: {

        counts: {
            title: '数量の統計',
            fieldName: {
                // 欄位名稱對照
                'company-counts': '会社数',
                'device-counts': '起動しているアルコールチェッカー数',
                'distributor-counts': '代理店の数',
                'edge-counts': 'EDGE 数',
                'manager-counts': '管理者数',
                'record-counts': 'アルコール測定記録の総合計回数',
                'site-counts': 'サイト数',
                'tester-counts': 'アルコール測定者数'
            },
            map: [
                // 欄位顯示順序，根據權限不同，不一定每一項都有
                'distributor-counts',
                'company-counts',
                'site-counts',
                'edge-counts',
                'manager-counts',
                'tester-counts',
                'device-counts',
                'record-counts'
            ],
            noData: '尚無統計資料' // 待翻譯
        },

        device: {
            title: 'アルコールチェッカーのステータス総覧',
            // remark: '使用回数が 1,800 回以上のアルコールチェッカーリスト',
            message: {
                loading: 'アルコールチェッカーのデータをロード中です...',
                fail: 'アルコールチェッカーのデータのロードに失敗しました。後ほど再試行してください',
                // notFound: '1,800 回以上使用されているアルコールチェッカーはありません'
            }
        }

    },

    // ----- 使用者登入後 ----- //
    user: {
        error: {
            msg1: '使用中の権限でエラーが発生しました。無効な会社またはサイトです。他の権限に切り替えるか、新しい権限をバインドしてください'
        }
    },

    /* ----- 使用者註冊、登入、帳號密碼、使用者選單、管理權限 ----- */
    account: {
        login: {
            welcome: 'クラウドへようこそ',
            slogan: 'オート撮影，クラウド管理，アルコール測定状態を手軽に把握できる',
            socialLogin: {
                google: 'Googleアカウントでログイン',
            },
            email: 'メールログイン',
            loginBtn: 'ログイン',
            altLoginBtn: 'ログインし直す',
            apiMsg: {
                'default': 'ログインに失敗しました。アカウントまたはパスワードが間違っています',
                '-4': 'Googleアカウントでログインしたことがある'
            }
        },
        signup: {
            title: 'アカウント登録',
            altTitle: 'コミュニティログイン',
            google: 'Googleのアカウントで登録する',
            input: {
                name: '氏名',
                email: 'メール',
                password: 'パスワード',
                passwordConfirm: 'パスワードの再確認',
                passwordConfirmError: '入力パスワードが異なる'
            },
            rulesLink: '',
            extraLink: '',
            signupBtn: '登録'
        },
        password: {
            title: 'パスワード',
            forgot: 'パスワードを忘れた？',
            hint: '登録に使用したEメール',
            resetBtn: 'パスワードの再設定'
        },
        oAuth: {
            title: '個人情報の保護',
            desc: '第三者のソーシャルメディアによる認証メカニズム(OAuth2.0 RFC6749)でのログインは、個人情報とシステムのデータの安全性の保護により優れた方法です。弊社はお客様のいかなるパスワードを知ることはなく、また保存することもありません。弊社にはお客様のソーシャルメディアのアカウントでの発言やいかなる画像にアクセスする権限もありません。お客様が企業ユーザーの従業員であるの場合、アカウントでのログインは管理アカウントとの連動のみを意味します。お客様のソーシャルメディアでの氏名とプロフィール写真はお客様ご自身のみ確認できますので、安心してシステムにログインしていただくことができます。'
        },
        match: {
            content:
            `<h2>管理者権限がない</h2>
            <hr />
            <p>管理者権限とは、クラウドプラットフォーム上での操作権限のことで、情報を見る権限や機能が多少異なる</p>
            <ul>
                <li>ユーザ管理：あなたの会社の下にある<strong>すべてのサイト</strong>の情報を見ることができます</li>
                <li>拠点管理：<strong>指定したサイト</strong>の情報を見ることができます</li>
            </ul>
            <p>アカウントに新しい管理者権限を追加するには、販売代理店または管理者にお問い合わせください</p>`,
            logout: 'ログインし直す'
        },
        control: {
            changePassword: 'パスワードを変更する',
            logout: 'ログアウト',
            match: 'より多くの管理権限と連動する',
            switch: '権限の切換え'
        },
        roles: {
            // 權限的排列順序
            order: ['1', '2', '3', '4', '5', '0'],

            // permission 為可新增的管理權限
            // tm 及 regular 目前系統未使用
            '1': {
                code: 'cem',
                title: 'CareExpert管理',
                permission: [1,2,3,4]
            },
            '2': {
                code: 'dm',
                title: '代理店管理',
                permission: [3,4]
            },
            '3': {
                code: 'com',
                title: 'ユーザ管理',
                permission: [3,4]
            },
            '4': {
                code: 'sm',
                title: '拠点管理',
                permission: [4]
            },
            '5': {
                code: 'tm',
                title: 'アルコールチェッカーの管理',
                permission: []
            },
            '0': {
                code: 'regular',
                title: '一般会員',
                permission: []
            }
        },
        privacy: 'プライバシーポリシー',
        terms: 'サービス条項'
    },

    /* ----- 各種操作回饋訊息 ----- */
    feedbackMsg: {
        // 空白內容提示
        emptyHint: {
            default: {
                title: '会社またはサイトが選択されていない',
                desc: '特定の項目へのソートを実行できます'
            },
            default2: {
                title: '会社が選択されていない',
                desc: '会社またはすべてのサイトを選択してください'
            },
            company: {
                title: '会社のデータがありません',
                desc: '現在データはありません'
            },
            site: {
                title: 'サイトのデータがありません',
                desc: 'この会社のサイトはありません'
            },
            noResult: {
                title: '現在この内容はありません',
                desc: '追加または後ほど再試行してください'
            },
            dataError: {
                title: 'データのフォーマットエラー',
                desc: '後ほど再試行してください'
            }
        },

        // 尚未選擇或缺少的項目
        lack: {
            company: '会社を選択してください',
            site: 'サイトを選択してください',
            keyword: 'キーワードが入力されていません'
        },

        // 搜尋相關
        search: {
            notFound: '一致する結果が見つかりません',
            notFound2: '一致する項目がありません'
        },

        // API 相關
        api: {
            sending: 'データ伝送中',
            querying: '検索中...',
            error1: {
                title: 'データのロードエラーです。エラーコード：{variable}',
                hint: '確認を押してページを整理してください'
            },
            error2: {
                title: 'データエラーです。データのフォーマットが正確かまたは重複していないか確認してください',
                hint: ''
            },
            error3: {
                title: 'エラーが発生しました。後ほど再試行してください',
                hint: ''
            },
            error4: {
                title: 'PULL Service APIs がオフです',
                hint: ''
            }
        },

        // 上傳檔案相關
        upload: {
            image: {
                success: '画像のアップロードに成功しました',
                fail: '画像のアップロードに失敗しました',
            }
        }

    },

    // ----- component: dialogBox ----- //
    dialogBox: {
        btn: {
            confirm: '確認',
            cancel: 'キャンセル',
            close: '閉じる'
        }
    },

    // ----- component: messageBox ----- //
    messageBox: {
        btn: {
            close: '閉じる'
        }
    },

    // ----- component: SortingTable ----- //
    sortingTable: {
        btn: {
            edit: '編集',
            delete: '削除'
        },

        error: {
            noCompany: {
                msg1: '会社と駅を選択してください',
            },
            noSite: {
                msg1: 'この会社にはまだ駅がありません',
                msg2: '駅を選択してください',
            },
            noData: {
                msg1: 'まだ何もない',
            }
        }

    },

    /* ----- 元件：selectBox ----- */
    selectBox: {
        coms: {
            label: '企業選択',
            hint: '会社名、統一編号またはIDで検索'
        },
        sites: {
            label: '拠点選択',
            hint: 'サイト名またはIDで検索'
        },
        empty: {
            company: '現在会社は登録されていません',
            site: '現在サイトは登録されていません'
        },
        all: {
            company: 'すべての会社',
            site: 'すべてのサイト'
        },
        notFound: '一致する項目はありません'
    },

    // ----- component: sideInfoBox ----- //
    sideInfoBox: {
        hint: {
            noData: '関連情報なし',
            close: '空白をクリックして閉じる'
        }
    },

    /* ----- 元件：slidebox ----- */
    slidebox: {
        btn: {
            confirm: '確認',
            cancel: 'キャンセル'
        },
        select: {
            city: {
                label: '県市',
                option: '県市の選択'
            },
            district: {
                label: '行政区',
                option: '行政区の選択'
            }
        },

        error1: {
            title: '更新内容はありません',
            hint: ''
        }
    },

    /* ----- 元件：批次停用 ----- */
    deviceDebatch: {
        trigger: 'バッチでの使用停止',
        btn: {
            cancel: '閉じる'
        },
        box: {
            title: 'バッチでのアルコールチェッカー使用停止',
            hint: '異なるシリアル番号はブランクで区切れば、バッチで複数のアルコールチェッカーを使用停止できます',
            error: 'シリアル番号を入力してください',
            success: '{variable} 個のアルコールチェッカーを使用停止しました'
        }
    },

    /* ----- 元件：Tagger ----- */
    tagger: {
        default: {
            hint: 'ラベルの追加：名称を入力後 Enter キーまたはプラスボタンを押すと追加できます',
            placeholder: 'ラベルの追加',
            emptyHint: 'ラベルが設定されていません'
        },

        // 公司進階設定：Operation types
        operationType: {
            hint: 'Add type：名称を入力後 Enter キーまたはプラスボタンで追加できます',
            placeholder: 'タイプの追加',
            emptyHint: 'オペレーションタイプが設定されていません'
        }
    },

    /* ----- 元件：重要系統公告 ----- */
    specialAnnouncement: {
        display: false,
        title: '重要なお知らせ',
        content: [
            '相容性問題：目前已知 Android 14 有機率造成酒測助手 APP 拍照時出現黑色區塊，導致照片部分內容被遮蔽，請安卓手機平板用戶<strong>暫緩升級至 Android 14</strong>，造成不便尚請見諒，待問題排除後將另行發佈公告。'
        ],
        close: '閉じる'
    },

    /* ----- 月份名稱 ----- */
    monthNames: [
        '1月', '2月', '3月', '4月', '5月', '6月',
        '7月', '8月', '9月', '10月', '11月', '12月',
    ],

    dayNameOfWeek: {
        Sunday: '日曜日',
        Monday: '月曜日',
        Tuesday: '火曜日',
        Wednesday: '水曜日',
        Thursday: '木曜日',
        Friday: '金曜日',
        Saturday: '土曜日',
    },

    /* ----- 元件：裝置月報表 ----- */
    deviceUsageReport: {
        checkin_time: 'Check-in 時間',
        company_name: '公司',
        // device_count_total: '累計量測次數',
        device_status: '狀態',
        dist_name: '經銷商',
        earliest_time: '最初量測時間',
        latest_time: '最後量測時間',
        last_updated: '最後更新時間',
        license_key: '授權碼',
        model_name: '型號',
        serial_no: '序號',
        test_count: '硬體使用次數',
        transfer_log: '轉移紀錄',

        // summary 中使用的
        group: '類別',
        startDate: '報表起算日',
        endDate: '報表結束日',

        enabled: '待命', // 0
        billed: '使用中', // 1
        spare: '備品', // 2
        sample: '樣品', // 3
        disabled: '已停用', // -1
        deleted: '已刪除', // -2

        // for export，用來產生 sheetHeader 中的欄位順序
        // 注意這邊只用來指定順序，欄位名稱必須要對應上面 deviceUsageReport 中列出的欄位名稱
        thReference: {
            summary: [
                '類別',
                '使用中', '待命', '已停用', '備品', '樣品', '已刪除',
                '報表起算日', '報表結束日'
            ],
            record: [
                '經銷商', '公司', '型號', '序號', '授權碼', '狀態', '量測次數',
                '最初量測時間', '最後量測時間', 'Check-in 時間', '最後更新時間'
            ]
        },

        // 轉移紀錄
        transferLog: {
            active: '状態',
            company_sname: '企業',
            initial_test_count: '転入時使用回数',
            site_sname: '拠点',
            timestamp: '転入時間',
        },

        ui: {
            trigger: '月次設置報告'
        },

        // message
        message: {
            noData: '無報表資料',
        }
    }, // end of 裝置月報表

    /* ----- 元件：授權碼報表 ----- */
    licenseUsageReport: {

        // 資料欄位
        'company': '公司名稱',
        'company_type': '公司類型',
        'enable_hr_bp': '血壓',
        'enable_punch_in': '打卡',
        'license_creation_time': '授權碼建立時間',
        'latest_measurement_time': '最近量測時間',
        'license_key': '授權碼',
        'license_update_time': '授權碼異動時間',

        'mode': '授權模式',
        'report_time': '報表結算日',
        'serial_no': '裝置序號',
        'site': '站點',

        'tester_employee_number': '工號',
        'tester_name': '受測者',

        // Summary 開通服務數量
        '_service': {
            'single': '使用服務數量 / 單人',
            'multi': '使用服務數量 / 多人',
            'alcohol': '酒測',
            'hrbp': '血壓',
            'punchin': '打卡',
        },

        '_clientSum': '<strong>經銷客戶端</strong>數量總計 (不含經銷商)</small>',
        '_deviceType': '裝置類型',
        '_idleTime': '裝置閒置天數',
        '_edgeCount': 'APP 用戶端數量',

        '_usageCount_alc': '使用次數/酒測',
        'usage_count_hrbp': '使用次數/血壓',
        'usage_count_punchin': '使用次數/打卡',

        ui: {
            title: '認証コード報告',
            button: {
                close: '閉じる',
                export: '輸出 Excel',
                trigger: '認証コード報告', // header 開啟授權碼報表功能的按鈕
                query: 'お問い合わせ'
            }
        },

        // 說明
        instruction: [
            '報表結算日：{reportTime}，請使用「匯出 Excel」功能查閱授權碼明細'
        ],

        exportNote: [
            '「報表結算日」為每月系統結算與儲存授權碼資料的時間，本報表內容均以此基準進行統計',
            '授權碼詳細使用紀錄請看「License list」工作表',
            'Summary：各公司使用中的服務數量',
            'Multimode usage：僅多人模式提供此分頁，多人模式下各站點的使用 (量測) 次數統計',
            'License list：授權碼明細',
            'Idle：30 天以上無使用紀錄的授權碼不列入統計，列在此分頁中作為備查',
            '',
            '欄位說明如下',
            '站點：授權碼綁定的站點，若此欄是空值代表「個人版自動授權」或「未綁定過的授權碼」',
            '',
            '以下欄位的值來自與授權碼關聯的「最近一次量測紀錄」',
            '授權模式：若此欄空值代表「查無此授權碼相關的量測紀錄」或「用戶端 APP 版本過舊」',
            '裝置序號：若此欄空值代表「查無此授權碼相關的量測紀錄」(無綁定的裝置或綁定後從未進行量測)',
            '裝置類型：根據「裝置序號」判斷是無線或有線酒測器，若此欄空值代表裝置非酒測器 (例如血壓計) 或無綁定的裝置或綁定後從未進行量測',
            '裝置閒置天數：從「最近量測時間」到「報表結算日」的天數，若此欄空值代表「查無此授權碼相關的量測紀錄」'
        ],

        // export (授權碼清單 only，統計頁的部分使用 table_to_sheet 產生)
        // 用來指定 sheetHeader 中的欄位順序
        // 會同時影響在表格中要顯示哪些欄位
        // 注意以下欄位名稱必須是 key 值轉換後的中文欄位名稱
        sheetHeader: {
            'License list': [
                '公司類型', '公司名稱', '站點', '授權模式',
                '授權碼', '授權碼建立時間', '授權碼異動時間',
                '使用次數/酒測', '使用次數/血壓', '使用次數/打卡',
                '裝置序號', '裝置類型', '最近量測時間', '裝置閒置天數',
                '受測者', '工號', '報表結算日'
            ],
            // 閒置未使用，欄位與 License list 相同
            'Idle': [
                '公司類型', '公司名稱', '站點', '授權模式',
                '授權碼', '授權碼建立時間', '授權碼異動時間',
                '使用次數/酒測', '使用次數/血壓', '使用次數/打卡',
                '裝置序號', '裝置類型', '最近量測時間', '裝置閒置天數',
                '受測者', '工號', '報表結算日'
            ],
            'Multimode usage': [
                '公司類型', '公司名稱', '站點', 'APP 用戶端數量',
                '使用次數/酒測', '使用次數/血壓', '使用次數/打卡',
                '報表結算日'
            ]
        }

    }, // end of 授權碼報表

    // ----- Dashboard ----- //
    dashboard: {
        tips: {
            initial: '会社と駅を選択してください',
            noCompany: '会社を選択してください',
            noSite: '駅を選択してください',
            noData: '指定されたデータが存在しない',
            syncData: 'は {variable} 秒後に自動的に更新されます。<br />または、このボタンをクリックして今すぐデータを更新します',
            scheduleDataFailed: 'スケジュールデータのロードに失敗',
            scheduleTimeDelay: '晚 {variable} 分', // 班表中，實際量測時間比預計量測時間晚
        },
        loading: {
            cancel: '積荷のキャンセル'
        },
        setting: {
            'today': '今日',
            'hr12': '12 <small>時間内</small>',
            'hr24': '24 <small>時間内</small>',
            'hr48': '48 <small>時間内</small>'
        },
        tag: {
            alcohol: '酒測',
            bloodPressure: '血壓',
            crossSite: '外站',
            departure: '發車',
            noSchedule: 'その日のスケジュール',
            punchin: '打卡',
        },
        panel: {
            title: {
                all: '全履歴',
                failed: '未合格',
                testers: '検査者',
                schedules: '今日班表'
            },
            // crossSite: '外站',
            employeeNumber: '工號：{variable}', // 受測者工號
            sum: '{variable} 筆', // 資料筆數

            tested: '検査済',
            untested: '未検査',
            others: '其他',

            warningValue: '低確定值',
            errorValue: '高確定值',

            siteName: {
                tester: '組織',
                record: '量測'
            }
        },
        // 可以看到「全部站點選項」的公司 id
        allSiteEnabled: {
            'TW': [12, 278], // 12 美食家, 278 銓勝 demo
            'JP': [],
        }
    },

    // ----- 檢核表 ----- //
    inspection: {
        sum: '{variable} 記録',
        sorting: {
            'label': '順番に並べる',
            'desc': '新しいのが手前にある',
            'asc': '古いのが手前にある',
            'formid': 'カテゴリー'
        },
        header: {
            'created': '設立日',
            'download': 'ダウンロード',
            'manager': '管理責任者',
            'siteName': '会社 / 拠点',
            'title': 'カテゴリー',
            'view': 'プレビュー'
        },
        TW001: {
            title: '台灣運輸管理自主檢查表'
        },
        JP001: {
            title: '酒気帯び確認記録',
        },

    },

    // ----- 使用次數說明 ----- //
    testCountTooltip: {
        trigger: '使用頻度',
        content:
        `<h2>ハードウェアの使用</h2>
        <p>これはテスターのファームウェアによって記録されたハードウェアの使用回数であり、<strong>ハードウェアの校正が必要かどうかを判断する基準として使用される</strong></p>
        <ul>
            <li>この数値は、ワインテスターを工場較正に戻すとゼロにリセットされます</li>
            <li>ワインテスターの転送操作は、ハードウェアの使用回数には影響しない</li>
            <li>このハードウェアの使用回数はAPPに表示される</li>
            <li>APPをオフラインで使用する場合、クラウドサーバーにデータをアップロードすることができないため、クラウド上で表示されるものとAPP上で表示されるものが異なる場合があります</li>
        </ul>
        <hr />
        <h2>クラウド利用</h2>
        <p>これは、<strong>利用回数に応じた課金</strong>の根拠となる、計測記録によるクラウドサーバーの利用回数です</p>
        <ul>
            <li>テスターが転送されると、システムは転送前の使用回数を記録し、クラウドに表示される数字は自動的に転送前の使用回数が差し引かれます</li>
            <li>APPはオフライン状態ではクラウドサーバーにデータをアップロードできないため、オフライン状態での使用回数をカウントすることができない</li>
        </ul>`,
    },

    // ----- 管理權限說明 ----- //
    managersTooltip: {
        trigger: '管理權限說明',
        content:
        `<h2>管理權限說明</h2>
        <p>管理權限是您在雲端平台上的操作權限，不同權限看到的資料及功能有些區別</p>
        <ul>
            <li>公司管理：可看到公司旗下<strong>所有站點</strong>的資料</li>
            <li>站點管理：可看到<strong>指定站點</strong>的資料</li>
            <li>您可以隨時為自己或他人建立新權限</li>
            <li>一個人可擁有多種不同權限，請開啟畫面右上角的使用者選單切換權限</li>
        </ul>
        <hr />
        <h2>權限綁定</h2>
        <ul>
            <li>管理權限建立後會自動與<strong>對應的雲端平台帳號</strong>綁定</li>
            <li>如果帳號綁定顯示「未綁定」，表示雲端平台上還沒有該帳號 Email 的使用者，請通知指定的對象註冊並登入雲端平台，就可自動完成綁定</li>
        </ul>`,
    },

    // ----- 變更密碼 ----- //
    changePwBox: {
        title: 'パスワードを変更する',
        label: {
            current: '旧パスワード',
            new: '新しいコード',
            confirm: '新しいパスワードの再入力'
        },
        msg: {
            failed: '変更に失敗しました。古いパスワードが正しいことを確認してください。',
            incorrect: '古いパスワードのエラー',
            mismatch: '同じ新しいパスワードを入力してください。',
        },
        success: {
            title: 'パスワード設定完了',
            msg: '次回から新しいパスワードを使用してください。',
            close: '閉じる'
        }
    },

}

module.exports = text;