import {showFeedbackMsg} from '../js/helper/feedbackMsg';

import axios from 'axios';
/*
使用範例
let apiData = null;
try {
    apiData = await sendApiRequest('post', '/managers/menu', null)
        .then(res => res.data);

} catch(error){
    globalErrorHandler(error);
    // ...其他錯誤處理程序
}
*/
export async function sendApiRequest(method, url, setting = null, token = null){

    // 若指定 token 參數 (帶入任意字串) 可模擬 token 過期的情況
    const xCsrfToken = token? token : document.querySelector('meta[name="csrf-token"]').content;

    let header = {
        'X-CSRF-TOKEN': xCsrfToken,
        'Content-Type': 'application/json'
    }

    if(token === 'none'){
        delete header['X-CSRF-TOKEN'];
    }

    // const server = '';
    // server 端需要允許 CORS
    // const server = await getSolServer();

    method = method.toLowerCase();

    return axios({
        headers: header,
        method: method,
        // url: `${server}${url}`,
        url: url,
        data: setting
      });
}

// ----- JWT token 請求 ----- //
export async function sendJWTRequest(token, method, url, data = null, signal = false){
    // signal: 可加入 signal 設定，目前用於 abortControl 取消請求

    const header = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    const setting = {
        headers: header,
        method: method.toLowerCase(),
        url: url
    }

    if(data){
        setting['data'] = data;
    }

    if(signal){
        setting['signal'] = signal;
    }

    return axios(setting);
}

// ----- 錯誤處理程序 ----- //
// error 是完整的錯誤訊息，錯誤代碼是 error.response.status
// 419: token 過期
export function globalErrorHandler(error){

    const apiErrBox = window.apiErrBox;

    const res = error.response;

    if(!res){
        return;
    }

    let statusText = '';
    let errMsg = '';

    if(res.statusText.length > 0){
        statusText = `<p>${res.statusText}</p>`;
    }

    if(res.data.message && res.data.message.length > 0){
        errMsg = `<p>${res.data.message}</p>`;
    }

    // api 錯誤訊息
    const apiErrTxt = window.langTxt.apiMsg.error.error1;

    const content =
    `<div class="error-msg">
        ${statusText}
        ${errMsg}
        <p>${apiErrTxt.tips}</p>
    </div>`;

    let title = apiErrTxt.msg;
    apiErrBox.set_title(title.replace('{variable}', res.status));

    apiErrBox.set_content(content);
    apiErrBox.open();
}

// ----- 針對 Azure API ----- //
export function sendAzureRequest(method, url, key, setting, type){

    method = method.toLowerCase();

    const headers = {
        'Content-Type': type,
        'Ocp-Apim-Subscription-Key': key // Azure API key
    }

    return axios({
        headers: headers,
        method: method,
        url: url,
        data: setting
      });
}

// ----- 取得 /managers/menu ----- //
// update: com, sm 權限按照 dashboard 規則加上 (?all_credentials=1) 參數
export async function getManagersMenu(){

    let result = null;

    if(window.managersMenuData){
        // 如果 global 變數中已經有資料，則使用現存的
        result = window.managersMenuData;
    } else {

        let apiData = null;

        try {
            const roleCode = window.mainUser.userRoleInfo.role;
            let url = '/managers/menu';
            if(roleCode === 'com' || roleCode === 'sm'){
                // crossRoleTest 暫時只作用在 dev 環境
                if(window.env === 'dev'){
                    url += '?all_credentials=1';
                }
            }

            apiData =
            await sendApiRequest('post', url, null)
            .then(res => res.data);
        } catch(error){
            // globalErrorHandler(error);
            showFeedbackMsg('管理權限資料錯誤', 'fail');
            return null;
        }

        // 成功拿到資料的情況
        if(apiData.ajaxcode === 0){
            result = apiData;
            // 儲存到 global 變數
            window.managersMenuData = apiData;
        }
        // 其他情況都會返回 null (尚未登入或 API 錯誤等)
    }
    return result;
}

// ----- 取得 /managers/menu 跨權限的資料 ----- //
// 取得跨權限的所有可見公司站點 (?all_credentials=1)
// 但是必須有公司管理或站點管理權限才列入計算
// 注意：目前只有在 dashboard 使用此種權限控管，為了不影響其他頁面，所以獨立一個 function
// 不能由 window.managersMenuData 中取得資料，也不能回存資料到此變數
export async function getManagersMenuAll(){

    const method = 'post';
    const url = '/managers/menu?all_credentials=1';

    let result = null;
    try {
        const apiData = await sendApiRequest(method, url, null)
            .then(res => res.data);

        if(apiData.ajaxcode === 0){
            result = apiData;
        }

    } catch(error){
        globalErrorHandler(error);
        // ...其他錯誤處理程序
    }

    return result;
}


// ----- 取得公告內容 ----- //
export async function getAnnouncement(){
    return axios.get('/assets/json/announcement.json',{
        // 避免 cache
        params: {
          'timestamp': Date.now()
        },
      });
}


// ----- 取得伺服器列表清單，並返回指定的 Url ----- //
// 正式站 https://sol.careexpert.life/EndPoint
// 測試站 https://soldev.careexpert.life/EndPoint
// zone: 'Taiwan' || 'Japan' || 'WebDev'
// services: 'Web' (Saturn) || 'Mgr' (Justus)
// dev" 正式站或測試站
export async function getEndPoint(service, zone = null){

    // TEST
    // window.serverInfo['app_env'] = 'staging';
    // window.serverInfo['app_env'] = 'production';
    // delete window.serverInfo['justus_api_url'];

    const appEnv = window.serverInfo['app_env'];

    try {
        const root = window.serverInfo['justus_api_url'];
        // staging 需要用正式站的 justus api
        // 所以此處要略過 staging，改用接下的程序判斷
        if(root.length > 0 && appEnv !== 'staging'){
            return root.replace(/\/api\b/g, '');
        }
    } catch(error){
        // do nothing
    }

    let list = null;

    // 優先使用 window.endpointlist
    if(window.endpointlist){
        list = window.endpointlist;
    }

    // 無現存資料需重新取得
    if(!list){
        // const subdomain = (window.env === 'dev')? 'soldev' : 'sol';
        let subdomain = 'sol';
        if(window.env === 'dev' && appEnv !== 'staging'){
            subdomain = 'soldev';
        }

        const params = '?returnAll=true';
        const url = `https://${subdomain}.careexpert.life/EndPoint${params}`;

        let apiData = null;
        apiData = await axios.get(url)
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                if(data.status === 'success'){
                    return data.data;
                } else {
                    return null;
                }
            })
            .catch((error) => {
                globalErrorHandler(error);
            });
        list = [...apiData];
        // 儲存取得的資料
        window.endpointlist = list;
        // localStorage.setItem('endpointlist', JSON.stringify(list));
    }

    // 根據 zone 值找出對應的資料，如果沒有傳入 zone 則根據 countryCode 自動判斷
    // 第一層 (預設找 Taiwan 的資料)
    if(!zone){
        zone = (window.countryCode === 'JP')? 'Japan' : 'Taiwan';
    }

    const dataItem1 = list.find((item) => {
        return item.zone === zone;
    });
    // 第二層
    const dataItem2 = dataItem1.services.find((item) => {
        return item.service === service;
    });

    return dataItem2.url;
}


// ----- 根據 user email 取的 server domain name ----- //
// 目前不需要此程序，改用 getEndPoint 取得伺服器列表清單
export async function getSolServer(email = null){

    let server = null;

    const localStorageData = localStorage.getItem('solserver');

    // console.log(window.solserver);
    // console.log(localStorageData);

    // 先檢查 window 或 localStorage 有沒有已存資料
    // 優先使用 window.solserver
    if(window.solserver){
        server = window.solserver;
    } else if(localStorageData){
        server = localStorageData;
    }

    if(!server){

        let userEmail = null;

        if(email){
            userEmail = email;
        } else if(window.mainUser){
            const userData = window.mainUser.rawData;
            const mail = userData.user.email;
            if(mail && mail.length > 0){
                userEmail = mail;
            }
        }

        if(userEmail){
            const url =
            `https://soldev.careexpert.life/Find/User/${userEmail}`;

            server = await axios.get(url)
                .then((res) => {
                    return res.data;
                })
                .then((data) => {
                    if(data.status === 'success'){
                        return data.data.Web;
                    } else {
                        return null;
                    }
                });

            if(server){
                window.solserver = server;
                localStorage.setItem('solserver', server);
            }
        }
    }
    // 如果沒有 server 資料的話返回空字串
    return (server)? server : '';
}