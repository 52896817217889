// {variable} 代表變數字串，會用程式帶入值
const text = {

    // ----- API ----- //
    apiMsg: {
        sending: '資料傳送中',
        querying: '查詢中...',
        error: {
            common: '資料讀取錯誤',
            error1: {
                msg: '資料載入錯誤，錯誤代碼：{variable}',
                tips: '請按確定重新整理畫面'
            },
        }
    },

    template: {
        // 尚未載入資料時的空白內容提示
        noContent: {
            msg: '歡迎使用銓勝酒測平台',
            tips: '尚未選取公司或站點'
        }
    },

    language: {
        tc: '中文',
        jp: '日本語',
        en: 'English'
    },

    // ----- terms / privacy page links ----- //
    privacy: [
        {
            title: '隱私政策',
            link: '/user/rules',
            newTab: true
        },
        {
            title: '服務條款',
            link: '/user/terms',
            newTab: true
        }
    ],

    // ----- footer ----- //
    footer: {
        title: '銓勝科技股份有限公司',
        address: '新竹縣竹北市光明六路東二段 358 號之 7',
        email: 'service@careexpert.life',
        links: [
            {
                title: '銓勝科技',
                link: 'https://www.careexpert.life/',
                newTab: true
            },
            {
                title: '銓勝酒測平台',
                link: '/',
                newTab: true
            },
            {
                title: 'Aceto 酮氣氣酮機',
                link: 'https://www.aceto.io/',
                newTab: true
            }
        ]
    },

    // ----- 主選單 ----- //
    nav: {
        coms: '公司列表',
        sites: '站點列表',
        edges: 'APP用戶端',
        devices: '裝置管理',
        managers: '管理權限表',
        testers: '受測者列表',
        records: '紀錄查詢',
        info: '即時資訊',
        inspection: '檢核表'
    },

    /* ----- 資料庫欄位名稱對照 (按字母順序) ----- */
    fieldName: {
        active: '狀態',
        addr: '地址',
        alcohol_value: '酒測值 (Raw)', // 酒測值，未經調整的原始數值

        bind_to: '對應的帳號',
        bp_dia_value: '舒張壓',
        bp_sys_value: '收縮壓',
        bp_dia_high_limit: '舒張壓 Max',
        bp_dia_low_limit: '舒張壓 Min',
        bp_sys_high_limit: '收縮壓 Max',
        bp_sys_low_limit: '收縮壓 Min',

        bp_required: '必量血壓',
        brand: 'Brand',
        buildnumber: 'Build',
        business_no: '統編',

        company_custom: '公司自訂',
        company_id: '公司',
        company_sname: '公司',
        contract_started_at: '合約起始日',
        contract_ended_at: '合約結束日',
        created_at: '新增日期',
        device_id: 'Device ID',
        devicemodel_name: '型號',
        edge_id: 'Edge ID',
        edge_sequence: 'Edge sequence',
        edge_type: '類型',
        email: 'Email',
        employee_number: '員工編號',
        error_value: '紅色警示',
        face_verify_result: '人臉驗證',
        fax: '傳真',
        fee_at: '每月請款日',
        final_value: '酒測值', // 酒測值，經過調整後的數值
        gain: 'Gain',
        geojson: '位置',
        heating_count: 'Heating',

        hr_value: '心率',
        hr_high_limit: '心率 Max',
        hr_low_limit: '心率 Min',

        id: 'ID',
        id_card_number: '身分證號',
        image: '照片',
        installed_at: 'Installed',
        last_seen_at: '最後上線時間',
        legal_value: '法定違規值',
        licensed_at: 'Licensed',
        license_id: 'LicenseID',
        license_key: '授權碼',
        limit_count: '酒測次數上限',
        log: 'Log',
        memo: '備註',
        mobile: '手機',
        model: 'Model',
        multiuser_mode: '授權模式',
        name: '姓名',
        name_alt: '名稱', // name 有可能非人名，而是裝置名稱，例如 edge 頁
        offset: 'Offset',
        operation_type: '標籤',
        osbuild: 'OSBuild',
        override_require_vehicle_info: 'APP 禁止略過車號輸入',
        pwm_for_engineering: 'PWM',
        recordable_type: '受測者類別',
        result: '結果',
        rfid_identifier: '感應卡',
        route_type: '類型群組',
        serial_no: '序號',
        site_custom: '站點自訂',
        site_id: '站點',
        site_sname: '站點',
        slope_ratio: 'Slope',
        sname: '量測站點',
        supervisors: '主管',
        tel1: '電話',
        test_count: '硬體使用次數',
        test_status: 'status',
        tested_at: '時間',
        tester_menu: '身份辨識',
        tester_note: '受測者備註',
        tester_sname: '編制站點',
        types: '管理權限',
        uniqueid: 'UniqueID',
        updated_at: '最後更新',
        user_id: 'UserID',
        vehicle_id_number: '車號',
        version: '版本',
        warning_value: '橘色警示',
        working_hours: '工時', // 暫時用這個 key 值

        // 工程用
        cal_nogas_adc: 'calNoGasADC',
        test_nogas_adc: 'testNoGasADC',
        test_min_adc: 'testMinADC',
    },

    /* ----- 前端自定義的欄位名稱對照 ----- */
    fieldNameCustom: {

        // 公司相關
        company: {
            name: '公司全名',
            sname: '顯示名稱',
            types: '公司類型',

            _address: '發票地址',
            _bp_dia: '舒張壓允許值',
            _bp_sys: '收縮壓允許值',
            _contract: '合約起訖',
            _distributor: '經銷商 ID',
            _hr: '心率允許值',
        },

        // 站點相關
        site: {
            name: '站點全名',
            sname: '顯示名稱'
        },

        _adc_ratio: 'test/cal', // testNoGasADC / calNoGasADC 用來判斷酒測器是否需要召回
        _appNote: '狀態',
        _bloodPressure: '血壓',
        _cumulativeCount: '雲端使用次數',
        _detail: '詳情',
        _face: 'Face', // 受測者列表
        _managerNote: '自訂備註', // 管理權限備註
        _testerNote: '自訂備註', // 受測者備註
        _usage: '使用紀錄', // 在 devices 中查詢「裝置轉移紀錄」、「最後幾筆量測紀錄」
        _tester: '受測者', // 在 edge 列表中顯示受測者資料
        bindAccount: '權限綁定', // 管理權限表 th
        licenseKey: '授權碼',

        // 超標處理狀態
        _record_statusId: '超標處理', // status_id
        _record_statusNote: '處理備註' // status_note
    },

    /* ----- 將欄位值代碼 (例如 0, -1) 轉換為文字 ----- */
    fieldParse: {

        // 裝置狀態 (括號中是舊版使用的名稱)
        // 這邊的名稱如果變動，裝置月報表 deviceUsageReport 中的名稱也需要調整
        active: {
            '0': '待命', // ENABLED (未啟用) 隨時可用的預備狀態，但尚未使用
            '1': '使用中', // ACTIVE (啟用) 吹氣一次以上就自動轉換為此狀態
            '2': '備品', // SPARE (備品) 隨時可用，由業務確認客戶的使用狀況
            '3': '樣品', // SAMPLE (樣品)
            '-1': '已停用', // DISABLED (停用)
            '-2': '已註銷' // DELETED (註銷)
        },

        // 綁定管理權限
        bindAccount: {
            bind: '已完成',
            unbound: '未綁定'
        },

        // devicemodels/list 'type' 值
        deviceModelType: {
            '0': '其他',
            '1': '有線酒測器',
            '2': '無線酒測器',
            '3': '血壓計',
            '4': '體溫計',
        },
        // device model 類別的排列順序
        deviceModelOrder: [
            2, 1, 3, 4, 0, 8
        ],

        // 批次新增或變更裝置狀態
        // API: '/devices/batch' 中 'op_type' 的參數值定義
        // 對應裝置狀態的 active 值
        deviceOpType: {
            'Add': '新增',
            'Spare': '備品',
            'Sample': '樣品',
            'Suspend': '停用',
            'Delete': '註銷',
        },

        // edge_type Edge 類型
        edgeType: {
            '1': 'Windows',
            '2': '無線酒測器',
            '3': '有線酒測器',
            '4': '無線血壓計',
            '5': '打卡機',
            'null': 'Unknown'
        },

        // Edge 的授權模式
        multiuserMode: {
            '0': '單人',
            '1': '多人',
            '2': '多人'
        },

        // 酒測結果
        result: {
            '0': '未通過',
            '1': '通過',
            '-1': '吹氣不足',
            '-2': '未完成',
            '-3': '時間異常'
        },

        // 超標處理狀態
        recordStatus: {
            '1': '取消',
            '2': '誤判',
            '3': '重測',
            '4': '直接出車',
            '5': '代班',
            '6': '其他',
        },

        // 人臉驗證結果
        faceVerifyResult: {
            '-1': '未啟用',
            '-2': 'Face API 異常',
            '0': '通過',
            '1': '未通過',
            '2': '未偵測到人臉',
            '3': '偵測到多個人臉',
            '4': '未建立人臉',
            '5': '群組不存在',
            '6': '搜尋人員失敗',
            '7': '搜尋人臉失敗',
            '8': '無網路'
        },

        // APP 禁止略過車號輸入
        overrideRequireVehicleInfo: {
            'default': '按公司設定', // null
            'on': '開啟', // true
            'off': '關閉' // false
        }

    },

    /* ----- 公司列表頁 ----- */
    pageComs: {

        list: {
            none: '無',
            notset: '未設定',
            feeRequest: '待請款'
        },

        button: {
            addCom: '新增公司',
            addTemplate: '建立公司範本',
            editCom: '基本資料',
            setting: '進階設定',
            showLicense: '授權碼',
            delete: '封存',
            reverse: '取消封存'
        },

        types: {
            '1': '直營客戶',
            '2': '經銷商',
            '3': '系統整合商',
            '4': '經銷客戶'
        },

        // 身份辨識方式
        tester_menu: {
            '1': '手機號碼',
            '2': '員工編號',
            '3': '身分證字號'
        },

        // 進階設定
        configBox: {
            // APP 使用模式設定值對照表
            appDefaultBindtesterTable: [
                {value: 1, title: '單人'},
                {value: 2, title: '多人'},
                {value: 3, title: '由 APP 設定'},
            ],
            // 欄位名稱
            fieldName: {
                // 主要服務
                'alcohol_enabled': '酒測器',
                'bp_hr_enabled': '血壓計',
                'punch_in_enabled': '打卡',

                // 自訂圖檔
                'bg_enable': '自訂酒測機背景圖',
                'notice_enable': '自訂酒測機公告圖',

                // 其他欄位按字母排序
                'app_default_bindtester': 'APP 使用模式',

                'custapipull_enable': 'PULL Service APIs',
                'custapi_secret': 'API 密碼',

                'enable_check_notification': 'APP 自動檢查並提醒<br />用戶端開啟通知功能',
                'enable_guest_mode': '強制使用訪客模式',
                'enable_qrcode_default': '預設使用非文字身分識別<br /><small>(例如 QR code 或條碼)</small>',
                'enable_show_route': '訪客模式：顯示路線',
                'enable_show_vehicle_id_number': '訪客模式：顯示車號',
                'enable_vehicle_info': 'APP 車號輸入',

                'face_recognition_enabled': '人臉辨識<br /><small>Face Recognition</small>',
                'face_verification_enabled': '人臉驗證<br /><small>Face Verification</small>',

                'require_vehicle_info': 'APP 禁止略過車號輸入',

                'schedule_enabled': '班表',
                'stopLimitEN': '酒測器禁用偵測<br /><small>禁用次數 {variable}</small>',
            },
            // 選項名稱
            options: {
                'on': '開啟',
                'off': '關閉'
            },
            // 選項名稱 alternate
            optionsAlt: {
                'on': '啟用',
                'off': '停用',
            },
            // 文字說明
            textContent: {
                // 主要服務的自訂標籤說明
                alcoholOptions: '酒測自訂標籤<small> ( 可設定 4 組，每組 4 個字以內 )</small>',
                bpHrOptions: '血壓自訂標籤<small> (可設定 4 組，每組 4 個字以內)</small>',
                punchInOptions: '打卡自訂標籤<small> ( 可設定 4 組，每組 4 個字以內 )</small>',
                // CustAPI 密碼規則
                apiSecretRule: '* 密碼必須為 8~16 個字元，數字加英文字母',
                // route type 類型群組
                routeTypesLabel: '類型群組<br /><small>( 可自訂群組名稱 )</small>',
                routeTypesPlaceholder: '例如：溫層'
            },
            // 操作訊息
            message: {
                custapiSecretInvalid: 'API 密碼格式錯誤',
                routeLabelMissing: '請設定類型群組名稱',
                success: '設定已更新',
                success64: '',
                // 缺少圖檔
                imgBgMissing: '請上傳酒測機背景圖',
                imgNoticeMissing: '請上傳酒測機公告圖',
                // 設定更新失敗
                failUpdatecfg: '設定更新失敗',
                failUpdatecfg64: '圖檔更新失敗'
            }
        },

        // 授權碼操作
        licenseBox: {

        },

        // 新增或編輯公司資料
        slidebox: {
            success: '資料更新完成',
            error: {
                business_no: '系統中已存在相同的統編',
            }
        },

        // 建立公司範本
        batchbox: {
            title: '建立公司範本',
            hint: '請輸入以下基本資料，系統將依序建立「公司、站點、管理權限、受測者」，稍後您可再手動修改資料內容',
            field: {
                bind_to: '管理帳號',
                business_no: '統編',
                distributor: '經銷商',
                name: '公司全名',
                sname: '顯示名稱',
                types: '公司類型'
            },
            // 預設值
            default: {
                siteName: '總站', // 站點名稱
                siteSName: '總站', // 站點簡稱
                managerNote: 'ADMIN', // 管理權限備註
                testerName: '預設受測者', // 受測者姓名
                testerNumber: '0000', // 受測者工號
            },
            btn: {
                submit: '開始',
                close: '關閉'
            },
            validation: {
                business_no: '只限英文字母與數字',
                noDistributor: '請選擇經銷商',
                required: '請輸入完整資料',
            },
            success: {
                company: '已建立公司：{variable}',
                site: '已建立站點：{variable}',
                manager: '已建立管理權限：{variable}',
                tester: '已建立受測者：{variable}',
                finish: '建立完成，您可繼續建立下一筆'
            },
            fail: {
                business_no: '系統中已存在相同的統編',
                company: '公司建立失敗'
            }
        }

    },

    /* ----- 站點列表頁 ----- */
    pageSites: {

        list: {
            none: '無',
        },

        view: {
            info: '站點資料',
            sorting: '今日數據'
        },

        info: '{name}：{data}', // 例如 站點自訂：無

        fastdial: {
            sum: '{variable} 個站點',
            placeholder: '輸入站點名稱'
        },

        control: {
            site: {
                add:'新增站點',
                edit: '編輯站點',
                delete: '刪除站點',
                delConfirm: '確定刪除此站點嗎？',
                inventorySetting: '庫存設定'
            },
            note: {
                add: '新增備註',
                edit: '編輯',
                delete: '刪除',
                delConfirm: '確定刪除此筆備註嗎？'
            }
        },

        // 站點備註
        noteDialog: {
            hint: '此備註專屬於您<strong>目前使用中的管理權限</strong>，您無法看到其他人或其他管理權限建立的備註內容'
        },

        msg: {
            noCompany: '需選擇公司',
            delCheckFailed: '無法刪除，此站點還有管理權限與受測者'
        }
    },

    // ----- Edges ----- //
    pageEdges: {

        sum: '共 {variable} 個 APP 用戶端',

        showSingleModeList: {
            btn: '單人模式用戶端',
            hint: '(單人模式用戶端可能無隸屬站點)'
        },

        btn: {
            queryUsage: '查詢', // 查詢使用紀錄
        },

        // 受測者欄位，hover 顯示更多資料
        tester: {
            employeeNumber: '員編：',
            mobile: '手機：'
        },

        // 查詢使用紀錄
        usageQuery: {
            title: '最近量測紀錄',
            noData: '無最近量測紀錄'
        }

    },

    /* ----- Devices ----- */
    pageDevices: {

        list: {
            searchKey: '搜尋條件：{variable}', // 顯示所輸入的搜尋條件

        },

        btn: {
            setting: '設定',
            queryUsage: '查詢',
        },

        // 裝置設定
        setting: {
            title: '裝置設定',
            action: {
                // 轉移到其他站點
                transfer: {
                    title: '轉移到其他站點'
                },
                // 變更序號
                serialNo: {
                    title: '變更序號 (此欄位留空表示不變更)',
                    placeholder: '請輸入新的序號'
                },
                // 變更狀態
                active: {
                    title: '變更狀態'
                }
            },
            fieldTitle: {
                id: 'ID：',
                modelName: '型號：',
                serialNo: '序號：',
                site: '站點：',
                status: '狀態：',
                licenseKey: '授權碼：',
            },
            msg: {
                m01: '未更新任何設定',
                m02: '請選擇站點',
                m03: '確定註銷此裝置嗎？',
                m04: '更新失敗，請確認序號是否重複了',
                m05: '設定完成',
            }
        },

        // 批次新增
        storeBatch: {
            title: '批次新增：{variable}',
            btn: {
                cancel: '取消',
                close: '關閉',
                confirm: '確定',
                trigger: '批次新增',
                tips: {
                    noSite: '需選擇站點'
                }
            },
            hint: '請選擇裝置型號；如果要一次新增多筆資料，請將完整序號以<strong>半形空白</strong>隔開',
            sum: '數量：{variable}',
            msg: {
                emptyInput: '請輸入序號',
                foundExist: '發現重複的裝置',
                invalidInput: '無效的序號',
                invalidModel: '請選擇裝置型號',
                success: '成功新增 {variable} 個裝置',
                successTransfer: '成功轉移 {variable} 個裝置',
            }
        },

        // 其他批次操作 (停用 suspend / 註銷 delete)
        batchAction: {
            title: '批次設定：{variable}',
            btn: {
                close: '關閉',
                confirm: '確定',
                trigger: '批次設定',
                tips: '可跨公司站點操作'
            },
            hint: '將不同序號以空白隔開，可批次處理多筆資料',
            sum: '數量：{variable}',
            msg: {
                success: '設定完成',
                invalidInput: '無效的序號',
                failed: '批次設定失敗',
                failedSN: '錯誤的序號：{variable}',
                failedUpdate: '設定失敗：{variable}'
            }
        },

        // 搜尋
        search: {
            placeholder: '搜尋裝置',
            remark: '請輸入欲查詢的序號，可支援單筆模糊查詢或多筆精確查詢<br />多筆查詢請輸入完整序號，並將不同序號以空白隔開',
            company: '公司',
            error: {
                noCompany: '尚未選擇公司'
            }
        }
    },

    /* ----- Managers ----- */
    pageManagers: {
        button: {
            add: '新增權限',
            edit: '編輯權限',
            bindExplain: '綁定說明',
            tips: {
                noSite: '需選擇站點'
            }
        },

        panel: {
            add: '新增權限：{variable}',
            edit: '編輯權限：{variable}',
        },

        message: {
            bindAccount: '管理權限需與帳號綁定後才可使用',
            supervisorsHint: '請輸入主管的員工編號，若輸入一位以上請以半形逗號分隔'
        },

        bindExplainBox: {
            title: '管理權限與帳號綁定說明',
            btn: {
                close: '確定',
                bindExplain: '綁定說明'
            }
        },

        bindHintBox: {
            title: '已新增管理權限',
            titleAlt: '權限綁定說明',
            desc:
            `<p>管理權限建立後會自動關聯對應的雲端平台帳號</p>
            <p>{variable}</p>
            <ul>
                <li>如果此帳號已註冊過，將自動完成綁定</li>
                <li>若尚未註冊，請通知指定對象使用此 Email 註冊並登入雲端平台，即可完成綁定</li>
            </ul>`,
            notSet: '尚未設定對應的雲端平台帳號'
        },

        delete: {
            alert1: '確認刪除此管理權限嗎？',
            alert2: '這是您目前所使用的管理權限，確定要刪除嗎？'
        },

        // 根據公司類型，限制可新增的管理權限
        // 注意此處的 Object key 值代表的是公司類型 (company types)
        // Array 中的值代表的是管理權限
        companyRules: {
            1: [3, 4], // 直營客戶 com, sm
            2: [2, 3, 4], // 經銷商 dm, com, sm
            3: [2, 3, 4], // 系統整合商 dm. com, sm
            4: [3, 4] // 經銷客戶 com, sm
        },

        // 管理權限數字轉換 (同 account.roles)
        roleCode: {
            '1': 'cem',
            '2': 'dm',
            '3': 'com',
            '4': 'sm',
            '5': 'tm',
            '6': 'regular',
        }
    },

    /* ----- Testers ----- */
    pageTesters: {
        // ex: 共 12 位受測者
        sum: '共 {variable} 位受測者',

        list: {
            btn: {
                edit: '編輯',
                delete: '刪除',
                transfer: '轉移',
            }
        },

        button: {
            add: '新增受測者',
            edit: '編輯受測者',
            close: '關閉',
            renewDbExplain: '如何手動更新資料庫？',
            tips: {
                noSite: '需選擇站點'
            }
        },

        panel: {
            add: '新增受測者：{variable}',
            edit: '編輯受測者：{variable}',
        },

        placeholder: {
            vehicleIdNumber: '車號範例：ABC-5678'
        },

        renewDbHintBox: {
            title: '已新增受測者',
            desc: '若您正在使用手機 APP 或 Windows 桌面版軟體，本次新增的受測者將在<strong>手動更新資料庫</strong>或<strong>重新啟動 APP / Windows 軟體</strong>後生效'
        },

        renewDbExplainBox: {
            title: '手動更新手機 APP 或 Windows 桌面版軟體的資料庫'
        },

        search: {
            placeholder: '搜尋受測者',
            hint: '以關鍵字搜尋受測者，可搜尋所選公司的所有受測者<br />按 Enter 鍵或 &#10132; 按鈕開始搜尋',
            condition: {
                type: '類別',
                company: '公司'
            },
            error: {
                nocompany: '需選擇公司',
                noinput: '未輸入關鍵字',
                notFound: '沒有符合的受測者',
                apiError: '發生錯誤，請稍後再試'
            },
            prefixTag: '搜尋結果'
        },

        batchStore: {
            trigger: '批次新增',
            box: {
                title: '批次新增受測者：{variable}',
                placeholder: '輸入格式範例：\r\n小明 123456 abc-1234\r\n小華 87654321',
                previewTitle: '已輸入的受測者資料 (單次新增上限 200 筆)',
                input: {
                    name: '姓名',
                    type: '{type}',
                    vehicle: '車號',
                    missing: '尚未輸入',
                    colon: '：' // 冒號
                },
                tips: {
                    t1: '受測者的{name}與{type}為必填，可選填車號',
                    t2: '請以「空白」區隔不同欄位，以「Enter鍵換行」區分不同受測者 (英文會自動轉換為大寫)',
                    t3: 'Excel 提示',
                },
                submit: '確定' // 確定送出按鈕
            },
            success: '成功新增 {variable} 名受測者',
            error: {
                custapiDisabled: {
                    title: 'PULL Service APIs 未開啟',
                    content: '請聯繫您的供應商開通此功能'
                },
                noinput: '尚未輸入內容',
                incomplete: '輸入的資料不完整'
            }
        },

        // 人臉辨識
        face: {
            title: '上傳受測者照片：{variable}',
            info: {
                company: '公司：',
                site: '站點：',
                name: '姓名：',
                number: '工號：',
                desc: '本系統不會儲存上傳的照片'
            },
            button: {
                invalid: '--',
                valid: '更新',
            },
            msg: {
                authAlert: '需「公司管理」或「站點管理」權限',
                uploadSuccess: '圖片上傳成功',
                uploadFail: '圖片上傳失敗',
            }
        },

        // 轉移受測者
        transfer: {
            desc: '將受測者轉移到其他站點',
            error: {
                noDestination: '尚未選取公司或站點',
                sameSite: '目的站點與原站點相同'
            },
            success: '轉移成功'
        },

        bpRequired: {
            'true': '是',
            'false': '--'
        },

        // 新增或編輯受測者資料
        slidebox: {
            success: '資料更新完成',
            error: {
                employee_number: '重複的員工編號',
            }
        },

        message: {
            deleteTester: '確定要刪除嗎？',
        }

    },

    /* ----- Records ----- */
    pageRecords: {

        panel: {
            date: {
                label: '日期：',
                today: '今日',
                yesterday: '昨日',
                thisweek: '本週',
                thismonth: '本月',
                lastmonth: '上月',
                range: '區間',
                rangePlaceholder: '選擇日期區間',
            },
            siteType: {
                label: '多人模式受測者：',
                test: {
                    label: '在此站量測',
                    desc: '包含訪客等所有人員，在此站點的量測記錄'
                },
                org: {
                    label: '編制在此站',
                    desc: '組織編制在此站點的人員量測記錄，量測站點可能為外站'
                },
                all: {
                    label: '全部'
                }
            },
            tester: {
                name: {
                    label: '姓名',
                    desc: '請輸入姓名 (可不完整)',
                },
                employeeNumber: {
                    label: '員工編號',
                    desc: '請輸入員工編號 (需完整)',
                },
                deviceId: {
                    label: 'Device',
                    desc: 'Device ID',
                },
                edgeId: {
                    label: 'Edge',
                    desc: 'Edge ID',
                },
                serialNo: {
                    label: 'SN',
                    desc: '序號 (可不完整)',
                }
            },
            others: {
                failedOnly: '只看未通過',
                unfinished: '包含未完成'
            },
            button: {
                createInspection: '建立檢核表',
                exportExcel: '匯出為 Excel',
                operation: '班次統計',
                showMap: '地圖',
                submit: '開始查詢',
                tab_crosscompany: '委外',
                tab_measure: '量測紀錄',
                tab_punch: '打卡紀錄',
            },
            // 此次查詢的查詢條件，顯示冒號
            queryCondition: {
                date: '日期：',
                name: '姓名：',
                employeeNumber: '員編：',
                resultFailed: '未通過'
            }
        },

        message: {
            emptyContent: '設定條件後請按「開始查詢」',
        },

        error: {
            msg1: '找不到量測紀錄，請試試其他查詢條件',
            msg2: '資料格式錯誤，請試試其他查詢條件',
        },

        crossCompanyFilter: {
            // 可以看到「委外」按鈕的公司 ID 白名單
            whiteList: {
                TW: [429], // #429 奇美實業
            }
        }
    },

    /* ----- 資訊統計頁 ----- */
    pageInfo: {

        counts: {
            title: '數量統計',
            fieldName: {
                // 欄位名稱對照
                'company-counts': '公司',
                'device-counts': '啟用裝置',
                'distributor-counts': '經銷商',
                'edge-counts': 'APP 用戶端',
                'manager-counts': '管理權限',
                'record-counts': '量測記錄',
                'site-counts': '站點',
                'tester-counts': '受測者'
            },
            map: [
                // 欄位顯示順序，根據權限不同，不一定每一項都有
                'distributor-counts',
                'company-counts',
                'site-counts',
                'edge-counts',
                'manager-counts',
                'tester-counts',
                'device-counts',
                'record-counts'
            ],
            noData: '尚無統計資料'
        },

        device: {
            title: '裝置狀態總覽',
            // remark: '使用次數超過 1,800 次酒測器列表',
            message: {
                loading: '資料載入中...',
                fail: '資料載入失敗，請稍後再試',
                // notFound: '沒有使用超過 1,800 次的酒測器'
            }
        }

    },

    // ----- 使用者登入後 ----- //
    user: {
        error: {
            msg1: '使用中的權限發生錯誤，無效的公司或站點，請切換其他權限或綁定新權限'
        }
    },

    /* ----- 使用者註冊、登入、帳號密碼、使用者選單、管理權限 ----- */
    account: {
        login: {
            welcome: '酒測助手管理平台',
            slogan: '自動拍照，雲端管理，輕鬆瞭解酒測狀態',
            socialLogin: {
                google: '使用 Google 帳號登入',
            },
            email: 'Email 登入',
            loginBtn: '登入',
            altLoginBtn: '重新登入',
            apiMsg: {
                'default': '登入失敗，帳號或密碼錯誤',
                '-4': '您前次使用的是 Google 帳號登入方式'
            }
        },
        signup: {
            title: '帳號註冊',
            altTitle: '或使用社群帳號登入',
            google: '使用 Google 帳號登入',
            input: {
                name: '姓名',
                email: 'Email，此為您的登入帳號',
                password: '登入密碼',
                passwordConfirm: '再次確認密碼',
                passwordConfirmError: '您輸入的密碼不一致'
            },
            rulesLink: '繼續代表您同意',
            extraLink: '已經註冊過',
            signupBtn: '註冊'
        },
        password: {
            title: '密碼',
            forgot: '忘記密碼？',
            hint: '請輸入您註冊時使用的 Email',
            resetBtn: '重設密碼'
        },
        oAuth: {
            title: '個資保護',
            desc: '使用第三方社群登入認證機制 (OAuth2.0 RFC6749) 是保護您個資與系統資料安全性的較佳方式，我們無從得知也不會儲存任何您的密碼，我們沒有權限在您的社群帳號貼文或存取任何圖片，若您是企業用戶員工，帳號登入僅作為綁定管理帳號使用，您的社群姓名與大頭貼也僅只有您自己能看見，您可以安心登入系統。'
        },
        match: {
            content:
            `<h2>無可用的管理權限</h2>
            <hr />
            <p>管理權限是您在此平台上的操作權限，不同權限看到的資料及功能有些區別</p>
            <ul>
                <li>公司管理：可看到公司旗下<strong>所有站點</strong>的資料</li>
                <li>站點管理：可看到<strong>指定站點</strong>的資料</li>
            </ul>
            <p>請聯繫經銷商或權限管理者，為您的酒測助手管理平台帳號增加管理權限</p>`,
            logout: '重新登入'
        },
        control: {
            changePassword: '變更密碼',
            logout: '登出',
            match: '綁定更多管理權限',
            switch: '切換權限'
        },
        roles: {
            // 權限的排列順序
            order: ['1', '2', '3', '4', '5', '0'],

            // permission 為可新增的管理權限
            // tm 及 regular 目前系統未使用
            '1': {
                code: 'cem',
                title: '銓勝管理',
                permission: [1,2,3,4]
            },
            '2': {
                code: 'dm',
                title: '經銷管理',
                permission: [3,4]
            },
            '3': {
                code: 'com',
                title: '公司管理',
                permission: [3,4]
            },
            '4': {
                code: 'sm',
                title: '站點管理',
                permission: [4]
            },
            '5': {
                code: 'tm',
                title: '酒測管理',
                permission: []
            },
            '0': {
                code: 'regular',
                title: '一般會員',
                permission: []
            }
        },
        privacy: '隱私權政策',
        terms: '服務條款'
    },

    /* ----- 各種操作回饋訊息 ----- */
    feedbackMsg: {
        // 空白內容提示
        emptyHint: {
            default: {
                title: '尚未選取公司或站點',
                desc: '您可針對特定項目進行排序'
            },
            default2: {
                title: '尚未選取任何公司',
                desc: '請先選擇公司或全部站點'
            },
            company: {
                title: '無公司資料',
                desc: '目前尚無資料'
            },
            site: {
                title: '無站點資料',
                desc: '此公司尚無站點'
            },
            noResult: {
                title: '目前尚無內容',
                desc: '請新增或稍後再試'
            },
            dataError: {
                title: '資料格式錯誤',
                desc: '請稍後再試'
            }
        },

        // 尚未選擇或缺少的項目
        lack: {
            company: '需選擇公司',
            site: '需選擇站點',
            keyword: '未輸入關鍵字'
        },

        // 搜尋相關
        search: {
            notFound: '沒有找到符合的結果',
            notFound2: '沒有符合的項目'
        },

        // API 相關
        api: {
            sending: '資料傳送中',
            querying: '查詢中...',
            error1: {
                title: '資料載入錯誤，錯誤代碼：{variable}',
                hint: '請按確認重新整理頁面'
            },
            error2: {
                title: '資料錯誤，請確認資料格式是否正確或重複',
                hint: ''
            },
            error3: {
                title: '發生錯誤，請稍後再試',
                hint: ''
            },
            // error4: {
            //     title: 'PULL Service APIs 未開啟',
            //     hint: ''
            // }
        },

        // 上傳檔案相關
        upload: {
            image: {
                success: '圖片上傳成功',
                fail: '圖片上傳失敗',
            }
        }

    },

    // ----- component: dialogBox ----- //
    dialogBox: {
        btn: {
            confirm: '確定',
            cancel: '取消',
            close: '關閉'
        }
    },

    // ----- component: messageBox ----- //
    messageBox: {
        btn: {
            close: '關閉'
        }
    },

    // ----- component: SortingTable ----- //
    sortingTable: {
        btn: {
            edit: '編輯',
            delete: '刪除'
        },

        error: {
            noCompany: {
                msg1: '請選擇公司與站點',
            },
            noSite: {
                msg1: '此公司尚無站點',
                msg2: '請選擇站點',
            },
            noData: {
                msg1: '目前尚無內容',
            }
        }

    },

    // ----- component: selectBox (公司站點選單) ----- //
    selectBox: {
        coms: {
            label: '請選擇公司',
            hint: '搜尋公司名稱、統編或 ID'
        },
        sites: {
            label: '請選擇站點',
            hint: '搜尋站點名稱或 ID'
        },
        empty: {
            company: '目前尚無公司',
            site: '目前尚無站點'
        },
        all: {
            company: '全部公司',
            site: '全部站點'
        },
        notFound: '沒有符合的項目' // 用關鍵字篩選時沒有符合的項目
    },

    // ----- component: sideInfoBox ----- //
    sideInfoBox: {
        hint: {
            noData: '無相關資料紀錄',
            close: '可按內容區以外任意空白處關閉'
        }
    },

    // ----- component: slidebox ----- //
    slidebox: {
        btn: {
            confirm: '確定',
            cancel: '取消'
        },
        select: {
            city: {
                label: '縣市',
                option: '選擇縣市'
            },
            district: {
                label: '行政區',
                option: '選擇行政區'
            }
        },

        error1: {
            title: '無更新的內容',
            hint: ''
        }
    },

    // ----- component: 批次停用 ----- //
    deviceDebatch: {
        trigger: '批次停用',
        btn: {
            cancel: '關閉'
        },
        box: {
            title: '批次停用酒測器',
            hint: '將不同序號以空白隔開，即可批次停用多筆酒測器',
            error: '請輸入序號',
            success: '已停用 {variable} 支酒測器'
        }
    },

    /* ----- 元件：Tagger ----- */
    tagger: {
        default: {
            hint: '新增標籤：輸入名稱後按 Enter 鍵或加號鈕新增',
            placeholder: '新增標籤',
            emptyHint: '尚未設定標籤'
        },

        // 公司進階設定：Operation types
        operationType: {
            hint: 'Add type：輸入名稱後按 Enter 鍵或加號鈕新增',
            placeholder: 'Add type',
            emptyHint: '尚未設定 Operation type'
        }
    },

    /* ----- 元件：重要訊息公告 ----- */
    specialAnnouncement: {
        display: false,
        title: '重要訊息公告',
        content: [
            '相容性問題：目前已知 Android 14 有機率造成酒測助手 APP 拍照時出現黑色區塊，導致照片部分內容被遮蔽，請安卓手機平板用戶<strong>暫緩升級至 Android 14</strong>，造成不便尚請見諒，待問題排除後將另行發佈公告。'
        ],
        close: '關閉'
    },

    /* ----- 月份名稱 ----- */
    monthNames: [
        '一月', '二月', '三月', '四月', '五月', '六月',
        '七月', '八月', '九月', '十月', '十一月', '十二月',
    ],

    dayNameOfWeek: {
        Sunday: '日',
        Monday: '一',
        Tuesday: '二',
        Wednesday: '三',
        Thursday: '四',
        Friday: '五',
        Saturday: '六',
    },

    /* ----- 元件：裝置月報表 ----- */
    deviceUsageReport: {
        checkin_time: 'Check-in 時間',
        company_name: '公司',
        // device_count_total: '累計量測次數',
        device_status: '狀態',
        dist_name: '經銷商',
        earliest_time: '最初量測時間',
        latest_time: '最後量測時間',
        last_updated: '最後更新時間',
        license_key: '授權碼',
        model_name: '型號',
        serial_no: '序號',
        test_count: '硬體使用次數',
        transfer_log: '轉移紀錄',

        // summary 中使用的
        group: '類別',
        startDate: '報表起算日',
        endDate: '報表結束日',

        enabled: '待命', // 0
        billed: '使用中', // 1
        spare: '備品', // 2
        sample: '樣品', // 3
        disabled: '已停用', // -1
        deleted: '已刪除', // -2

        // for export，用來產生 sheetHeader 中的欄位順序
        // 注意這邊只用來指定順序，欄位名稱必須要對應上面 deviceUsageReport 中列出的欄位名稱
        thReference: {
            summary: [
                '類別',
                '使用中', '待命', '已停用', '備品', '樣品', '已刪除',
                '報表起算日', '報表結束日'
            ],
            record: [
                '經銷商', '公司', '型號', '序號', '授權碼', '狀態', '量測次數',
                '最初量測時間', '最後量測時間', 'Check-in 時間', '最後更新時間'
            ]
        },

        // 轉移紀錄
        transferLog: {
            active: '狀態',
            company_sname: '公司',
            initial_test_count: '轉入時使用次數',
            site_sname: '站點',
            timestamp: '轉入時間',
        },

        ui: {
            trigger: '裝置月報表'
        },

        // message
        message: {
            noData: '無報表資料',
        }
    }, // end of 裝置月報表

    /* ----- 元件：授權碼報表 ----- */
    licenseUsageReport: {

        // 資料欄位
        'company': '公司名稱',
        'company_type': '公司類型',
        'enable_hr_bp': '血壓',
        'enable_punch_in': '打卡',
        'license_creation_time': '授權碼建立時間',
        'latest_measurement_time': '最近量測時間',
        'license_key': '授權碼',
        'license_update_time': '授權碼異動時間',

        'mode': '授權模式',
        'report_time': '報表結算日',
        'serial_no': '裝置序號',
        'site': '站點',

        'tester_employee_number': '工號',
        'tester_name': '受測者',

        // Summary 開通服務數量
        '_service': {
            'single': '使用服務數量 / 單人',
            'multi': '使用服務數量 / 多人',
            'alcohol': '酒測',
            'hrbp': '血壓',
            'punchin': '打卡',
        },

        '_clientSum': '<strong>經銷客戶端</strong>數量總計 (不含經銷商)</small>',
        '_deviceType': '裝置類型',
        '_idleTime': '裝置閒置天數',
        '_edgeCount': 'APP 用戶端數量',

        '_usageCount_alc': '使用次數/酒測',
        'usage_count_hrbp': '使用次數/血壓',
        'usage_count_punchin': '使用次數/打卡',

        ui: {
            title: '授權碼報表',
            button: {
                close: '關閉',
                export: '匯出 Excel',
                trigger: '授權碼報表', // header 開啟授權碼報表功能的按鈕
                query: '開始查詢'
            }
        },

        // 說明
        instruction: [
            '報表結算日：{reportTime}，請使用「匯出 Excel」功能查閱授權碼明細'
        ],

        exportNote: [
            '「報表結算日」為每月系統結算與儲存授權碼資料的時間，本報表內容均以此基準進行統計',
            '授權碼詳細使用紀錄請看「License list」工作表',
            'Summary：各公司使用中的服務數量',
            'Multimode usage：僅多人模式提供此分頁，多人模式下各站點的使用 (量測) 次數統計',
            'License list：授權碼明細',
            'Idle：30 天以上無使用紀錄的授權碼不列入統計，列在此分頁中作為備查',
            '',
            '欄位說明如下',
            '站點：授權碼綁定的站點，若此欄是空值代表「個人版自動授權」或「未綁定過的授權碼」',
            '',
            '以下欄位的值來自與授權碼關聯的「最近一次量測紀錄」',
            '授權模式：若此欄空值代表「查無此授權碼相關的量測紀錄」或「用戶端 APP 版本過舊」',
            '裝置序號：若此欄空值代表「查無此授權碼相關的量測紀錄」(無綁定的裝置或綁定後從未進行量測)',
            '裝置類型：根據「裝置序號」判斷是無線或有線酒測器，若此欄空值代表裝置非酒測器 (例如血壓計) 或無綁定的裝置或綁定後從未進行量測',
            '裝置閒置天數：從「最近量測時間」到「報表結算日」的天數，若此欄空值代表「查無此授權碼相關的量測紀錄」'
        ],

        // export (授權碼清單 only，統計頁的部分使用 table_to_sheet 產生)
        // 用來指定 sheetHeader 中的欄位順序
        // 會同時影響在表格中要顯示哪些欄位
        // 注意以下欄位名稱必須是 key 值轉換後的中文欄位名稱
        sheetHeader: {
            'License list': [
                '公司類型', '公司名稱', '站點', '授權模式',
                '授權碼', '授權碼建立時間', '授權碼異動時間',
                '使用次數/酒測', '使用次數/血壓', '使用次數/打卡',
                '裝置序號', '裝置類型', '最近量測時間', '裝置閒置天數',
                '受測者', '工號', '報表結算日'
            ],
            // 閒置未使用，欄位與 License list 相同
            'Idle': [
                '公司類型', '公司名稱', '站點', '授權模式',
                '授權碼', '授權碼建立時間', '授權碼異動時間',
                '使用次數/酒測', '使用次數/血壓', '使用次數/打卡',
                '裝置序號', '裝置類型', '最近量測時間', '裝置閒置天數',
                '受測者', '工號', '報表結算日'
            ],
            'Multimode usage': [
                '公司類型', '公司名稱', '站點', 'APP 用戶端數量',
                '使用次數/酒測', '使用次數/血壓', '使用次數/打卡',
                '報表結算日'
            ]
        }

    }, // end of 授權碼報表

    // ----- Dashboard ----- //
    dashboard: {
        tips: {
            initial: '請選擇公司與站點',
            noCompany: '請選擇公司',
            noSite: '請選擇站點',
            noData: '指定的資料不存在',
            syncData: '將於 {variable} 秒後自動更新，<br />或按此按鈕立即更新資料',
            scheduleDataFailed: '班表資料載入失敗',
            scheduleTimeDelay: '晚 {variable} 分', // 班表中，實際量測時間比預計量測時間晚
        },
        loading: {
            cancel: '取消載入'
        },
        setting: {
            'today': '今日',
            'hr12': '12 <small>小時內</small>',
            'hr24': '24 <small>小時內</small>',
            'hr48': '48 <small>小時內</small>'
        },
        tag: {
            alcohol: '酒測',
            bloodPressure: '血壓',
            crossSite: '外站',
            departure: '發車',
            noSchedule: '本日無班表',
            punchin: '打卡',
        },
        panel: {
            title: {
                all: '所有紀錄',
                failed: '未通過清單',
                testers: '本站編制',
                schedules: '今日班表'
            },
            // crossSite: '外站',
            employeeNumber: '工號：{variable}', // 受測者工號
            sum: '{variable} 筆', // 資料筆數

            tested: '有紀錄',
            untested: '無紀錄',
            others: '其他',

            warningValue: '橘色警示',
            errorValue: '紅色警示',

            siteName: {
                tester: '編制',
                record: '量測'
            }
        },
        // 可以看到「全部站點選項」的公司 id
        allSiteEnabled: {
            'TW': [12, 278], // 12 美食家, 278 銓勝 demo
            'JP': [],
        }
    },

    // ----- 檢核表 ----- //
    inspection: {
        sum: '{variable} 筆紀錄',
        sorting: {
            'label': '排序',
            'desc': '新的在前',
            'asc': '舊的在前',
            'formid': '類別'
        },
        header: {
            'created': '建立日期',
            'download': '下載',
            'manager': '管理者',
            'siteName': '公司 / 站點',
            'title': '類別',
            'view': '預覽'
        },
        TW001: {
            title: '台灣運輸管理自主檢查表'
        },
        JP001: {
            title: '酒気帯び確認記録',
        },

    },

    // ----- 使用次數說明 ----- //
    testCountTooltip: {
        trigger: '使用次數說明',
        content:
        `<h2>硬體使用次數</h2>
        <p>這是酒測器韌體所紀錄的硬體使用次數，作為<strong>硬體是否需要校正</strong>的依據</p>
        <ul>
            <li>酒測器返回原廠校正後此數字會歸零</li>
            <li>酒測器的轉移操作不影響硬體使用次數</li>
            <li>APP 上顯示的為此硬體使用次數</li>
            <li>APP 離線使用時無法上傳資料至雲端伺服器，因此在雲端上看到的有可能跟 APP 上顯示的不一致</li>
        </ul>
        <hr />
        <h2>雲端使用次數</h2>
        <p>這是雲端伺服器根據量測紀錄所統計的使用次數，作為<strong>按使用次數收費</strong>的依據</p>
        <ul>
            <li>當酒測器被轉移時，系統會記錄轉移前的使用次數，您在雲端看到的數字已自動扣除轉移前的次數</li>
            <li>APP 離線使用時無法上傳資料至雲端伺服器，因此將無法統計離線狀態下的使用次數</li>
        </ul>`,
    },

    // ----- 管理權限說明 ----- //
    managersTooltip: {
        trigger: '管理權限說明',
        content:
        `<h2>管理權限說明</h2>
        <p>管理權限是您在雲端平台上的操作權限，不同權限看到的資料及功能有些區別</p>
        <ul>
            <li>公司管理：可看到公司旗下<strong>所有站點</strong>的資料</li>
            <li>站點管理：可看到<strong>指定站點</strong>的資料</li>
            <li>您可以隨時為自己或他人建立新權限</li>
            <li>一個人可擁有多種不同權限，請開啟畫面右上角的使用者選單切換權限</li>
        </ul>
        <hr />
        <h2>權限綁定</h2>
        <ul>
            <li>管理權限建立後會自動與<strong>對應的雲端平台帳號</strong>綁定</li>
            <li>如果帳號綁定顯示「未綁定」，表示雲端平台上還沒有該帳號 Email 的使用者，請通知指定的對象註冊並登入雲端平台，就可自動完成綁定</li>
        </ul>`,
    },

    // ----- 變更密碼 ----- //
    changePwBox: {
        title: '變更密碼',
        label: {
            current: '請輸入舊密碼',
            new: '輸入新密碼',
            confirm: '再次輸入新密碼'
        },
        msg: {
            failed: '變更失敗，請確認舊密碼是否正確',
            incorrect: '舊密碼錯誤',
            mismatch: '請輸入相同的新密碼',
        },
        success: {
            title: '密碼設定完成',
            msg: '下次登入請使用新密碼',
            close: '關閉'
        }
    },

    // ----- slideBox 量測紀錄 ----- //
    slideRecord: {
        button: {
            close: '關閉'
        },
        msg: {

        }
    },

    // ----- 庫存設定 ----- //
    inventorySetting: {
        label: {
            threshold: '庫存門檻'
        },
        msg: {
            dataError: '資料讀取錯誤',
            noDataChanged: '未修改任何設定',
            success: '設定完成'
        }
    },

    // ----- 庫存警示 ----- //
    inventoryAlert: {
        trigger: '庫存警示',
        title: '庫存警示',
        hint: '以下為<strong>不足庫存門檻</strong>的裝置資訊，實際庫存量為「待命、備品、樣品」三種狀態的裝置數量',
        button: {
            close: '關閉',
            finished: '已處理',
            unsettled: '待處理'
        },
        cell: {
            'company': '公司',
            'site': '站點',
            'display_name': '型號',
            'minimum': '庫存門檻',
            'quantity': '實際庫存',
            'difference': '差距',
            '_control': '狀態'
        },
        msg: {
            error: '資料讀取錯誤'
        }
    }

}

module.exports = text;