import './licenseUsageReport.less';

import {
    sendApiRequest,
    globalErrorHandler,
    getManagersMenu
} from '../../js/api';

import {
    showLoadingMsg,
    hideLoadingMsg
} from '../../js/helper/helper';

import {dialogBox} from '../dialogBox/dialogBox';
import {comPicker} from './comPicker';

import _orderBy from 'lodash/orderBy';
import _groupBy from 'lodash/groupBy';
import _filter from 'lodash/filter';

import {showFeedbackMsg} from '../../js/helper/feedbackMsg';

// 匯出 xlsx
import {exportXlsx} from '../../js/helper/exportXlsx';

// 表格版型
import {
    countsTHtemplate,
    countsTableRow,
    exportNoteTableRow,
    sumTableRow
} from './table';

// 年月選擇器
import {monthPicker} from '../monthPicker/monthPicker';

import {parseDatetime} from '../../js/helper/helper';

// 測試資料
// import {data as sampleData} from './sample/sampleData'; // 單一公司
// import {data as sampleDistData} from './sample/sampleDistData'; // 經銷商

// dayjs
const dayjs = require('dayjs');
// const isBetween = require('dayjs/plugin/isBetween');
// dayjs.extend(isBetween);

// 匯出 xlsx (用在 table_to_sheet)
// const XLSX = require('xlsx');

export class licenseUsageReport {

    constructor(btnContainer, roleCode){

        if(!btnContainer){
            return;
        }

        // 插入觸發按鈕的 dom
        this.btnContainer = btnContainer;

        // 權限代碼 (小寫) ex 'cem'
        this.roleCode = roleCode;

        // 需要取得 managersMenu 來建立公司列表
        this.managersMenu = null;

        // dialogBox
        this.box = null;

        // 公司選擇器
        this.LURComPicker = null;

        // 儲存計算後的統計資料
        this.counts = [];

        // 儲存排序後的原始資料 (用來輸出 excel 細目)
        // 2024.23 更新，排除閒置未使用的項目
        this.orderedData = [];

        // 2024.23 更新，將閒置未使用的項目儲存於此，在匯出時使用
        this.idledData = [];

        // 年月選擇器
        this.LURMonthPicker = null;

        // 語言
        this.langTxt = window.langTxt.licenseUsageReport;

        // 語言：公司類型
        this.langComTypes = window.langTxt.pageComs.types;

        this.init();
        this.events();
    }

    async init(){

        // 加入觸發按鈕
        this.addTriggerBtn();

        // 建立 dialogBox
        this.box = new dialogBox('licenseUsageReportBox', false, false);

        // 初始化 dialogBox 內容
        this.initBoxContent();
    }

    /* ----- 加入觸發按鈕 ----- */
    addTriggerBtn(){

        const btn = document.createElement('div');
        btn.id = 'openLURbox';
        btn.className = 'btn-control';
        btn.innerHTML =
        `<svg>
            <use xlink:href="/assets/sprites/sprites_solid.svg#key"></use>
        </svg>${this.langTxt.ui.button.trigger}`;

        this.btnContainer.appendChild(btn);
    }

    events(){

        // trigger events
        document.querySelector('#openLURbox')
        .addEventListener('click', (event) => {
            this.box.open();
        });

        // box events
        const boxContainer = this.box.container;
        boxContainer.addEventListener('click', (event) => {

            // // 關閉，關閉時不清空內容
            if(event.target.id === 'closeLURBox'){
                this.box.close(false);
            }

            // 送出查詢
            if(event.target.id === 'LURQuerySubmit'){
                this.submitLURQuery();
            }

            // 匯出報表
            if(event.target.id === 'exportLURdata'){
                this.exportData();
            }

        });

    }

    /* ----- 初始化 dialogBox 內容 ----- */
    async initBoxContent(){

        const uiTxt = this.langTxt.ui;

        document.body.classList.add('loading');

        const boxDom = this.box.container;

        // 沒有資料的樣式
        boxDom.classList.add('no-data');

        const boxInnerwpr = boxDom.querySelector('.innerwpr');

        // get managersMenu data
        this.managersMenu = await getManagersMenu();

        const titleHtml =
        `<svg class="title-icon">
            <use xlink:href="/assets/sprites/sprites_solid.svg#key"></use>
        </svg>
        <p>${uiTxt.title}</p>
        <div class="control-menu">
            <div id="LURComPicker" class="picker"></div>
            <div id="LURMonthPicker" class="picker"></div>
            <div id="LURQuerySubmit">${uiTxt.button.query}</div>
        </div>`;
        this.box.set_title(titleHtml);

        // 設定按鈕，關閉與匯出
        const boxBtn =
        `<div class="btn" id="closeLURBox">${uiTxt.button.close}</div>
        <div class="btn white" id="exportLURdata">${uiTxt.button.export}</div>`;
        this.box.set_btnwpr(boxBtn);

        // 實體化 comPick 公司選擇器
        this.LURComPicker = new comPicker(
            this.managersMenu.data.coms,
            document.getElementById('LURComPicker')
        )

        // 實體化 monthPicker 年月選擇器
        this.LURMonthPicker = new monthPicker({
            container: document.getElementById('LURMonthPicker'),
            startYear: '2023', // 2023 年開始
            // endYear: '2024',
            monthNames: window.langTxt.monthNames
        });

        // 內容區加入空白提示
        boxInnerwpr.innerHTML =
        `<div class="empty-hint">
            <div class="content">
                <h4>歡迎使用授權碼報表查詢</h4>
                <p>請選擇公司</p>
            </div>
        </div>`;

        document.body.classList.remove('loading');
    }

    /* ----- 送出查詢 ----- */
    async submitLURQuery(){

        // 公司選單
        const comPickData = this.LURComPicker.pick;
        // 年月選單
        const monthPickData = this.LURMonthPicker.pick;

        if(!comPickData.comId){
            showFeedbackMsg('尚未選擇公司', 'fail');
            return;
        }

        if(!monthPickData.year || !monthPickData.month){
            showFeedbackMsg('日期選擇錯誤', 'fail');
            return;
        }

        const loadingMsg = '請稍候...此操作需要較長查詢時間'
        // active loading overlay
        showLoadingMsg(loadingMsg);

        const setting = {};
        if(comPickData.types === 2){
            // 經銷商
            setting.distributor_id = comPickData.comId;
        } else {
            setting.company_id = comPickData.comId;
        }

        // 年月設定
        setting.report_month = monthPickData.text;

        let apiData = null;

        try {
            apiData = await sendApiRequest('post', '/coms/licenseUsageReport', setting)
            .then(res => res.data);

        } catch(error){
            globalErrorHandler(error);
            // ...其他錯誤處理程序
        }

        this.dataProcessing(apiData.data.licenses);

        // deactive loading overlay
        hideLoadingMsg();
    }

    /* ----- 資料處理 ----- */
    dataProcessing(rawData){
        // 欄位代表的意思
        // l_edge_id: 可判斷有無綁定的用戶端
        // mode: 授權模式 (Single / Multi-APP / Multi-PC)
        // latest_measurement_time: 最後量測時間，可用來計算閒置天數

        // 沒有資料時顯示空白內容提示
        if(!rawData || rawData.length < 1){

            // 所選的公司
            const comPickData = this.LURComPicker.pick;

            // 所選的年月
            const pickMonth = this.LURMonthPicker.pick.text;

            const emptyHint =
            `<div class="empty-hint">
                <div class="content">
                    <h4>無報表資料</h4>
                    <p>${comPickData.name} / ${pickMonth}</p>
                </div>
            </div>`;

            const boxDom = this.box.container;
            boxDom.classList.add('no-data');
            boxDom.querySelector('.innerwpr').innerHTML = emptyHint;
            return;
        }

        // 將 rawData 區分為正常和閒置兩組 (30 天以上未使用)
        // 計算每一筆資料的閒置時間並存入自訂欄位 _idleTime
        const regularArr = [];
        const idleArr = [];

        rawData.forEach((item) => {
            // 根據以下兩個欄位計算，如果沒有 latest_measurement_time 也視為閒置
            // latest_measurement_time
            // report_time
            const idleTime = this.idleTimeCounter(
                item['latest_measurement_time'],
                item['report_time']
            );

            // 在 rawData 中也記錄此資訊
            item['_idleTime'] = idleTime;

            // hard copy 一份
            const data = {...item};
            data['_idleTime'] = idleTime;

            if(idleTime === null || idleTime > 30){
                // 閒置
                idleArr.push(data);
            } else {
                // 正常使用
                regularArr.push(data);
            }
        });

        this.orderedData = [];

        // 用原始資料排序，用來輸出 excel 細目，排序規格依序如下
        // 1. company_type: 公司類型 (asc)
        // 2. company_id: 公司 id (asc)
        // 3. site_id: 站點 id (asc)
        // 4. mode: 使用模式 (asc)
        // 5. latest_measurement_time: 最後量測時間 (desc)
        this.orderedData = _orderBy(
            regularArr,
            ['company_type', 'company_id', 'site_id', 'mode', 'latest_measurement_time'],
            ['asc', 'asc', 'asc', 'asc', 'desc']
        );

        // 用同樣的排序規則，儲存閒置的資料
        this.idledData = _orderBy(
            idleArr,
            ['company_type', 'company_id', 'site_id', 'mode', 'latest_measurement_time'],
            ['asc', 'asc', 'asc', 'asc', 'desc']
        );

        // 以下開始統計程序，儲存計算後的統計資料
        this.counts = [];

        // 有可能經篩選後無有效資料，只剩下閒置未使用的授權碼
        // 此時還是需要能夠產生統計表，只是數量均為 0
        // 所以在統計的時候要用 rawData 來判斷
        // 這邊不需要排序，等統計完成後再排序
        rawData.forEach((item) => {

            // 先找 counts 中有沒有此間公司的資料
            let comIndex = this.counts.findIndex((elem) => {
                return elem.company_id === item.company_id;
            });

            // 如果 counts 中沒有此公司的資料
            // 則加入初始資料到 counts 中 (未計數)
            if(comIndex === -1){

                const reportTime = (item['report_time'])? item['report_time'].slice(0, 19) : item['report_time'];

                const slot = {
                    'company_id': item['company_id'],
                    'company': item['company'],
                    'company_type': item['company_type'],

                    // 202307 版
                    // 每間公司開通的服務計費數量加總
                    // 需要根據每一筆授權碼的使用次數判斷，只要使用次數 >1，此處的數量就 +1
                    'service': {
                        // 授權模式：單人
                        'single': {
                            'alcohol': 0, // 酒測
                            'hrbp': 0, // 血壓
                            'punchin': 0 // 打卡
                        },
                        // 授權模式：多人
                        'multi': {
                            'alcohol': 0,
                            'hrbp': 0,
                            'punchin': 0
                        }
                    },

                    'report_time': reportTime // 報表結算日
                }
                const arrLength = this.counts.push(slot); // push 之後返回的是 Array 長度
                comIndex = arrLength - 1; // (push 後這筆資料的 index 值)
            }

            // 如果是閒置資料就略過不處理
            const idleTime = item['_idleTime'];
            if(idleTime === null || idleTime > 30){
                // 閒置
                return;
            }

            // 授權模式，只區分「單人」「多人」兩種
            let thisMode = null;
            switch(item.mode){
                case 'Single': // 單人 APP
                    thisMode = 'single';
                    break;

                case 'Multi-APP': // 多人 APP
                case 'Multi-PC': // 多人 PC (PC 端都是多人)
                    thisMode = 'multi';
                    break;
            }

            // 根據使用次數判斷服務計費數量
            // "usage_count_alc_pc": null,
            // "usage_count_alc_app": 20,
            // "usage_count_alc_app_kiosk": 5,
            // "usage_count_punchin": 3,
            // "usage_count_hrbp": 2,
            if(thisMode){
                // 只看有明確授權模式的授權碼資料

                // 酒測
                const countAlc =
                    item.usage_count_alc_pc +
                    item.usage_count_alc_app +
                    item.usage_count_alc_app_kiosk;

                if(countAlc > 0){
                    this.counts[comIndex]['service'][thisMode]['alcohol'] += 1;
                }

                // 血壓
                if(item.usage_count_hrbp && item.usage_count_hrbp > 0){
                    this.counts[comIndex]['service'][thisMode]['hrbp'] += 1;
                }

                // 打卡
                if(item.usage_count_punchin && item.usage_count_punchin > 0){
                    this.counts[comIndex]['service'][thisMode]['punchin'] += 1;
                }
            }

        }); // end of forEach

        // 統計結果，如果有一間公司以上，需要進行排序
        if(this.counts.length > 1){
            this.counts = _orderBy(
                this.counts,
                ['company_type', 'company_id'],
                ['asc', 'asc']
            )
        }

        this.showCounts(this.counts);
    }

    /* ----- 顯示資料整理後的統計數據 ----- */
    showCounts(Data){

        const langTxt = this.langTxt;

        // 說明
        let instruction = '<ul class="instruction">';
        langTxt.instruction.forEach((item) => {

            // 報表結算日
            if(/{reportTime}/.test(item)){
                // 因為每筆資料的 report_time 都相同
                // 所以只要取第一筆資料為代表
                try {
                    const datetime = parseDatetime(
                        Data[0]['report_time']
                    );
                    const output =
                    `${datetime.date.display} <small>${datetime.time}</small>`;
                    item = item.replace('{reportTime}', output);

                } catch(error){
                    console.log(error);
                }
            }

            instruction += `<li>${item}</li>`;
        });
        instruction += '</ul>';

        // 公司類型的文字對照表，來自文字檔中的 pageComs.types
        const comTypesTxt = window.langTxt.pageComs.types;

        // 表格內容
        let tableBody = '<tbody>';
        Data.forEach((slot) => {
            tableBody += countsTableRow(slot, comTypesTxt);
        });

        // 經銷客戶授權碼數量加總
        tableBody += sumTableRow(Data, langTxt);

        // 附加在表格底部的說明列
        tableBody += exportNoteTableRow(langTxt.exportNote);

        tableBody += '</tbody>';

        // 公司選單，使用者選取的公司
        const comPick = this.LURComPicker.pick;

        const Html =
        `<div class="sumPanel">
            <div class="info">
                <div class="group">
                    <div class="tag comid"># ${comPick.comId}</div>
                    <div class="tag type">${this.langComTypes[comPick.types]}</div>
                    <h4>${comPick.name}</h4>
                </div>
            </div>
            ${instruction}
        </div>
        <div class="reportPanel">
            <table>
                ${countsTHtemplate(langTxt)}
                ${tableBody}
            </table>
        </div>`;

        this.box.set_content(Html);

        const boxDom = this.box.container;
        boxDom.classList.remove('no-data');
    }

    /* ----- 匯出時的欄位資料轉換 ----- */
    parseExportData(dataItem, key){

        const value = dataItem[key];
        let output = '';

        switch(key){

            // 公司類型
            case 'company_type':
                output = (this.langComTypes[value])? this.langComTypes[value] : '';
                break;

            // 站點
            case 'site':
                if(dataItem.mode === 'Single'){
                    // 單人模式只有編制站點
                    output = (dataItem.tester_site)? dataItem.tester_site : '';
                } else {
                    output = (value)? value : '';
                }
                break;

            // 授權模式
            case 'mode':
                output = this.parseMode(dataItem);
                break;

            // 日期時間相關的欄位
            case 'latest_measurement_time':
            case 'license_creation_time':
            case 'license_update_time':
            case 'report_time':
                const datetime = parseDatetime(value, true);
                if(datetime){
                    output = datetime;
                }
                break;

            // 使用次數 (血壓，打卡)
            case 'usage_count_hrbp':
            case 'usage_count_punchin':
                output = (value)? value : 0;
                break;

            default:
                output = value;
        }

        return output;
    }

    // 判斷授權模式 (單人 / 多人)
    parseMode(data){

        let outputTxt = '';

        switch(data.mode){
            case 'Single':
                outputTxt += '單人';
                break;

            case 'Multi-APP':
            case 'Multi-PC':
                outputTxt += '多人';
                break;

            // default:
            //     outputTxt = data.mode;
        }
        return outputTxt;
    }

    /* ----- 匯出報表資料 ----- */
    exportData(){
        /*
        統計資料儲存在 this.counts
        清單資料儲存在 this.orderedData
        需要準備：
            1. sheetName: 在 xlsx 中要產生的 sheet 名稱
            2. sheetHeader: 欄位名稱與顯示順序
            3. sheetData: 資料
        */

        // 顯示載入中提示，這個提示會在 exportXlsx 中移除
        document.body.classList.add('loading');

        const langTxt = this.langTxt;

        // 一個 xlsx 檔中可生成多個 sheet，在此指定各個 sheet 的名稱 (相當於資料分頁)
        const sheetName = ['Summary', 'License list', 'Idle'];

        // sheetHeader 指定需要顯示的欄位以及顯示順序
        const sheetHeader = langTxt.sheetHeader;

        // sheetData 報表資料，需整理成以下範例格式
        // sheet 的 key 值對應 sheetName
        // 個別資料物件中的 key 值對應 sheetHeader 中的欄位名稱
        /*
        {
            'Summary': [{
                公司名稱: 'xxx',
                公司類型: 'xxx',
                授權模式: 'xxx',
                ...
            }, ...],
            'License list': [{
                站點: 'xxx',
                授權模式: 'xxx',
                授權碼: 'xxx',
                裝置序號: 'xxx',
                ...
            }, ...]
        }
        */
        // 先根據 sheetName 來產生空白的 sheetData
        const sheetData = {};
        sheetName.forEach((item) => {
            sheetData[item] = [];
        });

        // sheet1. Summary 使用 table_to_sheet 產生

        // sheet2. License list
        sheetData['License list'] = this.organizeSheetData(
            this.orderedData,
            sheetHeader['License list']
        )
        /*
        const thHeader2 = sheetHeader['License list'];
        const outputData2 = [];

        this.orderedData.forEach((item) => {
            const entry = {};
            for(const key in item){
                // 取得中文欄位名稱
                const fieldName = langTxt[key];
                // 如果在 sheetHeader 中包含此中文名稱，才需要紀錄值
                if(thHeader2.includes(fieldName)){
                    entry[fieldName] = this.parseExportData(item, key);
                }
            }

            // 需要額外加上的資訊
            // 1. 裝置閒置天數 (在 dataProcessing 中計算)
            const fieldName_idleTime = langTxt['_idleTime'];
            entry[fieldName_idleTime] = item['_idleTime'];

            // 2. 裝置類型
            const fieldName_deviceType = langTxt['_deviceType'];
            entry[fieldName_deviceType] = this.deviceType(item.serial_no);

            // 使用次數 (酒測)
            const fieldName_usageCountAlc = langTxt['_usageCount_alc'];
            entry[fieldName_usageCountAlc] = this.alcUsageCount(item);

            outputData2.push(entry);
        });

        sheetData['License list'] = [...outputData2];
        */

        // 閒置未使用
        sheetData['Idle'] = this.organizeSheetData(
            this.idledData,
            sheetHeader['Idle']
        )

        // sheet3. Multimode usage 多人模式的使用次數統計
        const multimodeCounts =
        this.multimodeUsageCount(langTxt);

        if(multimodeCounts.length > 0){
            // 資料數量大於 0 的話才加入到 sheet 中
            // sheetName.push('Multimode usage');
            // 加在第二個分頁
            sheetName.splice(1, 0, 'Multimode usage');
            sheetData['Multimode usage'] = multimodeCounts;
        }

        const comPickData = this.LURComPicker.pick;
        const companyName = comPickData.name;

        // 報表結算日，日期
        let time = '';
        if(this.counts[0]['report_time']){
            const datetime = parseDatetime(this.counts[0]['report_time'], true);
            time = `_${datetime.slice(0,10)}`;
        }

        // 輸出檔名範例
        // 易通通訊有限公司_授權碼報表_2023-10-16.xlsx
        const fileName =
        `${companyName}_${langTxt.ui.title}${time}`;

        exportXlsx(sheetName, sheetData, sheetHeader, fileName, 20, {
            name: 'Summary',
            content: XLSX.utils.table_to_sheet(
                document.querySelector('#licenseUsageReportBox table')
            )
        });

    }

    // ----- 傳入指定的 sheetHeader 與資料，整理需要的資料 ----- //
    organizeSheetData(Data ,sheetHeader){

        const langTxt = this.langTxt;

        const result = [];

        Data.forEach((item) => {

            const entry = {};

            for(const key in item){
                // 取得中文欄位名稱
                const fieldName = langTxt[key];
                // 如果在 sheetHeader 中包含此中文名稱，才需要紀錄值
                if(sheetHeader.includes(fieldName)){
                    entry[fieldName] = this.parseExportData(item, key);
                }
            }

            // 需要額外加上的資訊
            // 1. 裝置閒置天數 (在 dataProcessing 中計算)
            const fieldName_idleTime = langTxt['_idleTime'];
            entry[fieldName_idleTime] = item['_idleTime'];

            // 2. 裝置類型
            const fieldName_deviceType = langTxt['_deviceType'];
            entry[fieldName_deviceType] = this.deviceType(item.serial_no);

            // 使用次數 (酒測)
            const fieldName_usageCountAlc = langTxt['_usageCount_alc'];
            entry[fieldName_usageCountAlc] = this.alcUsageCount(item);

            result.push(entry);

        });

        return result;
    }

    // ----- 計算裝置閒置天數 ----- //
    idleTimeCounter(latestMeasurementTime, reportTime){
        // latestMeasurementTime: 最近量測時間，此欄位有可能是 null
        // reportTime: 報表在伺服器產生的時間

        let idleTimes = null;

        if(latestMeasurementTime && reportTime){
            const date1 = dayjs(latestMeasurementTime);
            const date2 = dayjs(reportTime);

            idleTimes = date2.diff(date1, 'day');
            if(idleTimes < 0){
                idleTimes = 0;
            }
        }

        return idleTimes;
    }

    // ----- 根據序號判斷裝置類型 ----- //
    // 用序號判斷「無線 (PAB) / 有線 (CAB)」
    // 序號有可能不存在 (在報表產生當下為解除綁定的狀態) 或非酒測器
    deviceType(serialNo){
        // 傳入 serialNo 裝置序號

        let outputTxt = '';

        if(/\bpab/i.test(serialNo)){
            outputTxt = '無線酒測器';
        } else if(/\bcab/i.test(serialNo)){
            outputTxt = '有線酒測器';
        }

        return outputTxt;
    }

    // ----- 計算使用次數 (酒測) ----- //
    alcUsageCount(dataItem){
        // APP 單人: usage_count_alc_app
        // APP 多人: usage_count_alc_app_kiosk
        // PC 多人:  usage_count_alc_pc
        // 如果報表區間內沒有任何紀錄，欄位值會是 null
        const count =
        dataItem.usage_count_alc_app +
        dataItem.usage_count_alc_app_kiosk +
        dataItem.usage_count_alc_pc;

        return count;
    }

    // ----- 計算多人模式的使用次數統計 ----- //
    multimodeUsageCount(langTxt){
        // langTxt: 用來將欄位轉換成中文

        // 需要先整理原始資料
        // 以站點為單位計算各站點中，不同服務的使用次數總和
        const siteUsage = [];

        // rawDataItem 每一筆授權碼的原始資料
        this.orderedData.forEach((rawDataItem) => {

            // 只需要看多人模式的資料，並且需要有站點
            if(rawDataItem.mode !== 'Multi-APP' && rawDataItem.mode !== 'Multi-PC'){
                return;
            }
            if(!rawDataItem['site_id']){
                return;
            }

            // 先找 siteUsage 中有沒有這個站點的資料
            let siteIndex = siteUsage.findIndex((elem) => {
                return elem.site_id === rawDataItem.site_id;
            });

            // 如果沒有的話，將初始資料加入到 siteUsage 中 (尚未計算數量)
            if(siteIndex === -1){

                // 報表結算日
                const reportTime =
                (rawDataItem['report_time'])? rawDataItem['report_time'].slice(0, 19) : rawDataItem['report_time'];

                const slot = {
                    'company_type': rawDataItem['company_type'],
                    'company': rawDataItem['company'],
                    'site_id': rawDataItem['site_id'],
                    'site': rawDataItem['site'],
                    '_edgeCount': 0, // 與授權碼綁定的 edge (APP 用戶端) 數量
                    '_usageCount_alc': 0, // 使用次數/酒測
                    'usage_count_hrbp': 0, // 使用次數/血壓
                    'usage_count_punchin': 0, // 使用次數/打卡
                    'report_time': reportTime // 報表結算日
                };

                const arrLength = siteUsage.push(slot); // push 之後返回的是 Array 長度
                siteIndex = arrLength - 1; // (push 後這筆資料的 index 值)
            }

            // 此站點在 siteUsage 中對應的資料位置 siteUsage[siteIndex]

            // 1. 與授權碼綁定的 edge (APP 用戶端) 數量
            // 根據序號欄位判斷
            if(rawDataItem.serial_no){
                siteUsage[siteIndex]['_edgeCount'] += 1;
            }

            // 2. 酒測使用次數
            const countAlc =
                rawDataItem['usage_count_alc_pc'] +
                rawDataItem['usage_count_alc_app'] +
                rawDataItem['usage_count_alc_app_kiosk'];
            siteUsage[siteIndex]['_usageCount_alc'] += countAlc;

            // 3. 血壓 / 打卡使用次數
            siteUsage[siteIndex]['usage_count_hrbp'] += rawDataItem['usage_count_hrbp'];
            siteUsage[siteIndex]['usage_count_punchin'] += rawDataItem['usage_count_punchin'];

        });

        const result = [];

        // 將 siteUsage 中的欄位替換為中文，再將替換後的資料儲存到 result 中
        siteUsage.forEach((item) => {

            // 刪除不需要的欄位
            delete item['site_id'];

            const slot = {};
            for(const prop in item){
                const fieldName = langTxt[prop]; // 欄位中文名稱
                slot[fieldName] = this.parseExportData(item, prop);
            }

            result.push(slot);
        });

        return result;
    }

}