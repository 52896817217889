import './inventoryAlert.less';

import {
    sendApiRequest,
    // globalErrorHandler,
    getManagersMenu
} from '../../js/api';

import {dialogBox} from '../dialogBox/dialogBox';

import {showFeedbackMsg} from '../../js/helper/feedbackMsg';

import _orderBy from 'lodash/orderBy';

// import {Data as sampleData} from './sample';

export class inventoryAlert {
    // triggerContainer 放置按鈕的地方 (extraBtnWpr)
    constructor(triggerContainer){

        this.triggerContainer = triggerContainer;

        this.trigger = null;

        // 紀錄少於庫存的總數
        // 來自 this.getCount 取得的數量
        this.counts = 0;

        this.managersMenu = null;
        this.comsData = null;
        this.sitesData = null;

        // dialogBox
        this.boxId = 'inventoryAlert';
        this.box = new dialogBox(this.boxId, true, false, 'fullwidth');

        // UI text
        this.boxTxt = window.langTxt.inventoryAlert;

        // 表格欄位顯示順序
        this.tableMap = [
            'company',
            'site',
            'display_name',
            'minimum',
            'quantity',
            'difference',
            // '_control'
        ];

        // milliseconds, 檢查資料的時間間隔 (每五分鐘檢查一次)
        this.timeInterval = 5 * 60 * 1000;
        // 檢查資料更新的 timer
        this.timer = null;

        if(this.boxTxt){
            this.init();
            this.events();
        }

        // API 測試
        // this.apiTest();
    }

    // ----- API 測試 ----- //
    async apiTest(){
        document.body.classList.add('loading');

        let method = 'get'; // 取得資料
        // let method = 'post'; // 設定

        // 取得庫存警示，只回傳數量
        let url = '/api/inventories?count';
        // 取得庫存警示列表資料
        // let url = '/api/inventories';

        // get 指定公司的庫存
        // let url = '/api/inventories?company_id=278';
        // get 指定站點的庫存
        // let url = 'api/inventories?site_id=1';

        // post 設定站點的庫存
        // `/api/inventories`

        let setting = null;
        // let setting = [
        //     {
        //         site_id:1,
        //         device_model_id: 1,
        //         minimum: 50
        //     },
        //     {
        //         site_id:1,
        //         device_model_id: 4,
        //         minimum: 50
        //     }
        // ];

        let apiData = null;
        try {
            apiData = await sendApiRequest(method, url, setting)
                .then(res => res.data);

        } catch(error){
            globalErrorHandler(error);
            // ...其他錯誤處理程序
        }
        document.body.classList.remove('loading');

        console.log(apiData);
    }

    async init(){

        // 需用來顯示公司站點的名稱
        this.managersMenu = await getManagersMenu();
        this.comsData = this.managersMenu.data.coms;
        this.sitesData = this.managersMenu.data.sites;

        const alertCount = `<span class="inventory-alert-count">--</span>`;

        // trigger button
        this.trigger = document.createElement('div');
        this.trigger.id = 'inventoryAlertTrigger';
        // 預設為 disabled 狀態
        this.trigger.className = 'btn-control disabled';
        this.trigger.innerHTML =
        `${this.boxTxt.trigger}${alertCount}`;

        this.triggerContainer.appendChild(this.trigger);

        // 取得庫存警示數量，並設定 trigger 狀態
        this.getCount();

        // dialog box content
        const titleHtml =
        `<svg class="title-icon">
            <use xlink:href="/assets/sprites/sprites_solid.svg#dolly-flatbed"></use>
        </svg>${this.boxTxt.title}${alertCount}`;

        this.box.set_title(titleHtml);

        this.box.set_hint(`<p>${this.boxTxt.hint}</p>`);

        // 設定按鈕，只需要「關閉」
        const boxBtn =
        `<div class="btn" id="${this.boxId}Close">${this.boxTxt.button.close}</div>`;
        this.box.set_btnwpr(boxBtn);
    }

    // ----- 取得庫存警示數量，並設定 trigger 狀態 ----- //
    async getCount(){

        let result = 0;
        // const url = '/api/sites/inventories?count=true';
        const url = '/api/inventories?count';

        try {
            const apiData = await sendApiRequest('get', url, null)
                .then(res => res.data);

            if(apiData && apiData > 0){
                result = apiData;
            }
        } catch(error){
            // globalErrorHandler(error);
            // ...其他錯誤處理程序
            // console.log(error);
        }

        // TEST
        // result = 0;
        // console.log(result);

        // 自動再次取得資料
        if(this.timer){
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.getCount();
        }, this.timeInterval);

        // 數量沒有變動，不需更新畫面
        if(result === this.counts){
            return;
        }

        this.counts = result;

        let textNumber = '--';

        if(result > 0){
            this.trigger.classList.remove('disabled');
            textNumber = result;
        } else {
            this.trigger.classList.add('disabled');
        }

        try {
            // badge 中的數量顯示
            this.trigger.querySelector('.inventory-alert-count').textContent = textNumber;
            // dialogBox title 中的數量顯示
            const boxContainer = this.box.container;
            boxContainer.querySelector('.inventory-alert-count').textContent = textNumber;
        } catch(error){
            console.log(error);
        }
    }

    events(){

        // 按鈕
        this.triggerContainer.addEventListener('click', (event) => {

            if(event.target === this.trigger && this.counts > 0){
                // 取得資料後開啟 dialogBox 顯示
                this.showInventoryTable();
            }

        });

        // box DOM
        const boxContainer = this.box.container;
        boxContainer.addEventListener('click', (event) => {

            // 關閉
            if(event.target.id === `${this.boxId}Close`){
                this.box.close(false);
            }

            // 處理狀態按鈕
            if(event.target.classList.contains('btn-status')){
                const parent = event.target.parentNode;
                const btns = parent.querySelectorAll('.btn-status');
                btns.forEach((btn) => {
                    if(btn === event.target){
                        btn.classList.add('active');
                    } else {
                        btn.classList.remove('active');
                    }
                });
            }

        });

    }

    // ----- 取得資料後開啟 dialogBox 顯示 ----- //
    async showInventoryTable(){

        // const url = '/api/sites/inventories';
        const url = '/api/inventories';

        document.body.classList.add('loading');
        try {
            const apiData = await sendApiRequest('get', url, null)
                .then(res => res.data);

            // 資料排序
            // * 公司 id
            // * 站點 id
            // * 庫存差距 (實際庫存 - 庫存門檻)
            const orderData = _orderBy(
                apiData.data,
                ['company_id', 'site_id', (item) => {
                    const diff = item.quantity - item.minimum;
                    return diff;
                }],
                ['desc', 'desc', 'asc']
            )

            // console.log(apiData);
            this.box.set_content(
                this.tableContent(orderData)
            );
            this.box.open();

        } catch(error){
            console.log(error);
            // 資料讀取錯誤
            showFeedbackMsg(this.boxTxt.msg.error, 'fail');
        }
        document.body.classList.remove('loading');

    }

    // ----- 欄位處理 ----- //
    fieldProcess(DataItem, key){

        let output = '';

        switch(key){

            case 'company':
                try {
                    const com = this.comsData.find((item) => {
                        return item.id === DataItem['company_id'];
                    });
                    output = com.sname;
                } catch(error){
                    // console.log(DataItem['company_id'])
                    // console.log(error);
                }
                break;

            case 'site':
                try {
                    const site = this.sitesData.find((item) => {
                        return item.id === DataItem['site_id'];
                    });
                    output = site.sname;
                } catch(error){
                    // console.log(DataItem['site_id'])
                    // console.log(error);
                }
                break;

            case 'display_name':
                // 裝置型號，轉換為大寫
                output = DataItem[key].toUpperCase();
                break;

            case 'difference':
                // 庫存差距 (實際庫存 - 庫存門檻) (應該都會是負數)
                output = DataItem['quantity'] - DataItem['minimum'];
                break;

            // 暫時無作用
            case '_control':
                const status = DataItem['status'];
                output =
                `<div class="wrapper">
                    <div class="btn-status btn${(status === 1)? ' active': ''}">${this.boxTxt.button.unsettled}</div>
                    <div class="btn-status btn${(status === 2)? ' active': ''}">${this.boxTxt.button.finished}</div>
                </div>`;
                break;

            default:
                output = DataItem[key];
        }

        return output;
    }

    // ----- 表格內容 ----- //
    tableContent(Data){

        let tableHead = '';
        let tableContent = '';

        const cellName = this.boxTxt.cell;

        this.tableMap.forEach((key) => {
            // table head
            tableHead += `<th class="${key}">${cellName[key]}</th>`;
        });

        Data.forEach((dataItem) => {
            let roll = '<tr>';

            this.tableMap.forEach((key) => {
                roll +=
                `<td class="${key}">${this.fieldProcess(dataItem, key)}</td>`;
            });

            roll += '</tr>';
            tableContent += roll;
        });

        const Html =
        `<div class="tablewpr">
            <table>
                <thead><tr>${tableHead}</tr></thead>
                <tbody>${tableContent}</tbody>
            </table>
        </div>`;

        return Html;
    }

}