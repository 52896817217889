import './sideInfoBox.less';

export class sideInfoBox {

    constructor(id){

        this.id = id;

        this.box = document.createElement('div');
        this.box.id = id;
        this.box.className = 'side-info-box';

        this.boxText = window.langTxt.sideInfoBox;

        this.init();

    }

    get container(){
        return this.box;
    }

    init(){

        const Html =
        `<div class="overlay"></div>
        <div class="content">
            <h2 class="title"></h2>
            <div class="scrollwpr">
                <div class="innerwpr">
                    <div class="empty-hint">No Data Found</div>
                </div>
            </div>
            <div class="btn-close">
                <div class="icon"></div>
            </div>
            <div class="box-hint">
                <p>${this.boxText.hint.close}</p>
            </div>
        </div>`;

        this.box.innerHTML = Html;
        document.body.appendChild(this.box);

        this.events();
    }

    events(){

        this.box.addEventListener('click', (event) => {

            if(event.target.classList.contains('btn-close')){
                this.close();
            }

            if(event.target.classList.contains('overlay')){
                this.close();
            }

        });

    }

    open(){
        this.box.classList.add('show');
        this.box.querySelector('.scrollwpr').scrollTo(0,0);
    }

    close(){
        this.box.classList.remove('show');
        this.clearContent();
    }

    /* ----- 清空標題與內容 ----- */
    clearContent(){
        this.set_title('');
        this.set_content('');
    }

    /* ----- 設定標題 (innerHTML) ----- */
    set_title(html){
        this.box.querySelector('.title').innerHTML = html;
    }

    /* ----- 設定提示文字或額外內容 (innerHTML) ----- */
    // set_hint(html){
        // this.box.querySelector('.hint').innerHTML = html;
    // }

    /* ----- 設定內容區塊 (innerHTML) ----- */
    set_content(html){
        this.box.querySelector('.innerwpr').innerHTML = html;
    }

    /* ----- No Data Found 找不到資料時的提示 ----- */
    set_noData(){
        this.set_content(
            `<div class="empty-hint">${this.boxText.hint.noData}</div>`
        )
    }

}